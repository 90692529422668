// @flow

import * as R from "ramda";
import { mergeDeep } from "lib";
import type { Channel } from "types";
import type { SyncReducer } from "./types";

const indexByTopic = R.indexBy(R.prop("topic"));
export const initListeners: SyncReducer<Channel[]> = (state, _, channels) => {
	return R.over(
		R.lensProp("listeners"),
		mergeDeep(R.__, indexByTopic(channels)),
		state
	);
};

export const addListener: SyncReducer<Channel> = (state, _, channel) => {
	// $FlowFixMe
	return R.assoc("listeners", channel.topic, state);
};
