// @flow
import * as R from "ramda";
import { fetchPolls, findLine, findLineFull, postPoll } from "network/requests";
import type {
	Line,
	Office,
	Poll,
	PollNotification,
	State,
	Ticket,
} from "types";
import type { Reducer } from "../types";

const mapTicketToPoll =
	(state: State) =>
	async (ticket: Ticket): Promise<PollNotification> => {
		const { office_slug } = await findLine(ticket.line_id);
		const office: ?Office = R.pathOr(
			undefined,
			["offices", office_slug],
			state
		);
		if (!office) throw Error("missing office");
		const { name } = await findLineFull(ticket.line_id);
		const poll_notif: PollNotification = {
			ticket,
			line: name,
			office,
		};
		return poll_notif;
	};

export const getPolls: Reducer<> = async (state, dispatch) => {
	const token = state.user.token;
	if (!token) {
		console.warn("Cannot fetch Polls. User not found");
		return;
	}
	let tickets: Ticket[];
	try {
		tickets = await fetchPolls(token);
	} catch (e) {
		console.error(e);
		return;
	}
	const polls: ?(PollNotification[]) = await Promise.all(
		tickets.map(mapTicketToPoll(state))
	).catch(console.error);
	// TODO: verificar que si se llama a getPolls() más de
	// una vez, no se dupliquen las notificaciones.
	if (polls) {
		dispatch.addNotification(polls.map((poll) => ({ poll, type: "poll" })));
		dispatch.setPolls(polls);
	}
};

export const submitPoll: Reducer<Poll> = async (state, dispatch, poll) => {
	if (state.guest) throw Error("User is guest");
	const token = state.user.token;
	await postPoll(token, poll);
	await dispatch.popPoll();
};
