/* eslint-disable no-undef */
import {
	memoizeWith,
	identity,
	curry,
	pipe,
	prop,
	pick,
	indexBy,
	path,
	map,
	values,
	keys,
} from "ramda";
import * as errors from "./errors.js";
import { getGlobal } from "reactn";
import { join } from "./socket";
import axios from "axios";
// import config from "config";
import config from "./config";

// const env = {
// 	production: {
// 		domain: "zeroq.cl",
// 		env: "prod",
// 	},
// 	staging: {
// 		domain: "staging.zeroq.cl",
// 		env: "staging",
// 	},
// 	lab: {
// 		domain: "lab.zeroq.cl",
// 		env: "lab",
// 	},
// 	dev: {
// 		domain: "dev.zeroq.cl",
// 		env: "dev",
// 	},
// };

// const environment = () => {
// 	const development = process.env.REACT_APP_GIT_TAG === "development";
// 	if (window && window.location.hostname === env.production.domain)
// 		return env.production.env;

// 	if (
// 		(window && window.location.hostname === env.staging.domain) ||
// 		development
// 	) {
// 		return env.staging.env;
// 	}

// 	return env.lab.env;
// };

const { apiOrigin, subDomain, featureFlags } = config;

const dinamicUrl = apiOrigin;

const subdomain = subDomain;

const envDomain = process.env.REACT_APP_DOMAIN;

const domain = envDomain || dinamicUrl;
const baseUrl = envDomain
	? `https://${domain}`
	: `https://${subdomain}zeroq.cl`;
const apiBaseURL = `${baseUrl}/api/v1`;
const API_V3_BASE_URL = `${baseUrl}/api/v3`;
// const baseUrl = `https://${subdomain}zeroq.cl`;
const officesAPI = `${apiBaseURL}/offices`;
const officesAPIWithActiveCateogories = `${API_V3_BASE_URL}/offices/web`;
const stateAPI = `${apiBaseURL}/state`;
const linesAPI = `${apiBaseURL}/lines`;

const memo = memoizeWith(identity);

const with_auth = (token) =>
	axios.create({
		baseURL: apiBaseURL,
		headers: { Authorization: token },
	});

const with_auth_get = curry((path, token) =>
	with_auth(token).get(path).then(getData)
);

const with_auth_post = curry((path, token, args) =>
	with_auth(token).post(path, args).then(getData)
);

const getData = path(["data", "data"]);
const indexBySlug = indexBy(prop("slug"));

export const fetchOffices = memo(() =>
	axios.get(officesAPIWithActiveCateogories).then(getData).then(indexBySlug)
);

export const fetchOffice = memo((slug) =>
	axios.get(`${stateAPI}/${slug}`).then(prop("data"))
);

export const fetchLinesState = memo((office_slug) =>
	axios
		.get(`${stateAPI}/${office_slug}/lines`)
		.then(prop("data"))
		.then(map(pick(["slug", "waiting", "wait"])))
		.then(values)
);

export const fetchLines = memo((office_id) =>
	axios.get(`${officesAPI}/${office_id}/lines`).then(getData)
);

export const fetchModulos = memo((office_slug) =>
	axios.get(`${stateAPI}/${office_slug}/modulos`)
);

export const fetchLine = memo((office_slug, line_slug) =>
	axios
		.get(`${stateAPI}/${office_slug}/lines/${line_slug}`)
		.then(prop("data"))
);

export const fetchRestrictions = memo((office_slug, token) =>
	axios
		.get(
			`${baseUrl}/services/reservations/api/v2/offices/${office_slug}/settings/`,
			{
				headers: {
					Authorization: token,
				},
			}
		)
		.then(prop("data"))
);

export function requestTicket(office_slug, line_id, user_id, channel) {
	return new Promise((res, rej) => {
		const ref = channel.on("ticket:created", (response) => {
			if (response.line_id === line_id && response.user_id === user_id) {
				channel.off("ticket:created", ref);
				res(response);
			}
		});

		channel
			.push("ticket:new", { line_id, user_id }, 8000)
			.receive("ok", (msg) => console.log("ticket:new:", msg))
			.receive("error", rej)
			.receive("timeout", () =>
				rej(new errors.TicketNewTimeoutException())
			);
	});
}

export const requestReserve = (token, args) =>
	with_auth_post("/user/reserves", token, args);

export const fetchTickets = with_auth_get("user/tickets");
export const fetchReserves = with_auth_get("user/reserves");

export const subscribeToTickets = (cb) => {
	const { tickets } = getGlobal();
	const office_slugs = pipe(indexBy(prop("office_slug")), keys)(tickets);
	office_slugs.forEach(async (slug) => {
		const channel = await join(slug);
		channel.on("call:created", (call) => {
			cb(call);
		});
	});
};

export const fetchCategories = memo(() =>
	axios.get(`${apiBaseURL}/categories`).then(getData)
);

export const findLine = memo((line_id) => {
	if (!line_id) {
		return Promise.reject(Error("[Request findLine] undefined line_id"));
	}
	return axios
		.get(`${linesAPI}/${line_id}/info`)
		.then(pipe(prop("data"), pick(["office_slug", "line_slug"])));
});

export const findLineFull = memo((line_id) => {
	if (!line_id) {
		return Promise.reject(Error("[Request findLine] undefined line_id"));
	}
	return axios.get(`${linesAPI}/${line_id}`).then((l) => l.data.data);
});

export const fetchHourBlocks = memo((office_id) =>
	axios.get(`${officesAPI}/${office_id}/hours`).then(getData)
);

export const postPoll = with_auth_post("/user/polls");
export const fetchPolls = with_auth_get("/user/polls");

export const fetchTicketQR = memo((ticket_id) =>
	axios.get(`${baseUrl}/user/v2/tickets/${ticket_id}`).then(getData)
);

export const getWait = (token, body) =>
	axios.get(`${dinamicUrl}/services/wait/api/v1/customerwaiting`, {
		params: body,
		headers: { Authorization: token },
		timeout: 5000,
	});

export const getFeatureFlags = () => {
	return fetch(`https://growthbook-api.zeroq.cl/api/features/${featureFlags}`)
		.then((res) => res.json())
		.then((parsed) => {
			return parsed.features;
		});
};

export const fetchOfficeById = memo((officeId) =>
	axios.get(`${baseUrl}/api/v1/offices/${officeId}`).then(prop("data"))
);
