// @flow

import {
	loginWithPass,
	signUpWithPass,
	temporaryLogin,
	updateTemporaryUser,
} from "network/login";
import { signIn } from "network/firebase";
import type { Reducer } from "../types";

interface DoLoginWithPassArgs {
	email: ?string;
	password: ?string;
}

/**
 * Sends the login credentials to the server and waits for a response.
 * @async
 * @throws on invalid email/password
 */
export const doLoginWithPass: Reducer<DoLoginWithPassArgs> = async (
	state,
	dispatch,
	{ email, password }
) => {
	const userData = await loginWithPass(email, password);
	if (!userData.data) {
		throw new Error(userData.message);
		return;
	}
	await dispatch.setUser(userData.data);
	await dispatch.setGuest(false);
	await dispatch.askUserPosition();
	dispatch.loadUserData();
};

export const doLoginWithUserData = async (state, dispatch, { data }) => {
	await dispatch.setUser(data);
	await dispatch.setGuest(false);
	// await dispatch.askUserPosition();
	// dispatch.loadUserData();
};

/**
 * Uses a a provider to login/signup to the service.
 * @async
 */
export const doLoginWithProvider: Reducer<"facebook" | "google"> = async (
	state,
	dispatch,
	provider
) => {
	const { providers } = state;
	const response = await signIn(providers)(provider);
	await dispatch.setUser(response);
	await dispatch.setGuest(false);
	await dispatch.askUserPosition();
	dispatch.loadUserData();
};

export const doLoginAsGuest: Reducer<> = async (state, dispatch) => {
	if (state.guest) return;
	dispatch.setGuest(true);
};

type SignUpArgs = {
	email: string,
	password: string,
};
export const doTemporaryLogin = async (
	state,
	dispatch,
	{ sessionId, email, phone, name, rut, dni },
	captchaToken
) => {
	const object = { captchaToken, sessionId, email, phone, name, rut, dni };
	const temporaryUserData = await temporaryLogin(object);
	if (!temporaryUserData.data) {
		throw new Error(temporaryUserData.message);
		return;
	}

	await dispatch.setUser(temporaryUserData.data);
	await dispatch.setGuest(false);
	await dispatch.askUserPosition();
};
export const doUpdateTemporaryUser = async (
	state,
	dispatch,
	{ token, email, phone, name, rut, dni }
) => {
	const object = { email, phone, name, rut, token, dni };
	const temporaryUserData = await updateTemporaryUser(object);
	if (!temporaryUserData.data) {
		throw new Error(temporaryUserData.message);
		return;
	}

	await dispatch.setUser(temporaryUserData.data);
	await dispatch.setGuest(false);
	await dispatch.askUserPosition();
};

export const doSignUpWithPass: Reducer<SignUpArgs> = async (
	state,
	dispatch,
	user
) => {
	const response = await signUpWithPass(user);
	if (response.message && !response.token) throw new Error(response.message);

	if (!response.token) throw new Error(response.message);
	await dispatch.setUser(response.data);
	await dispatch.setGuest(false);
	await dispatch.askUserPosition();
	dispatch.loadUserData();
};

export const logout: Reducer<> = async (state, dispatch) => {
	await dispatch.unsetUser();
	await dispatch.setGuest(true);
	await dispatch.setPolls([]);
	await dispatch.askUserPosition();
	Object.keys(state.channels.byId).forEach((officeSlug) =>
		dispatch.leaveChannel({ officeSlug })
	);
};
