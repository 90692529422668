// import "./polyfills";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Socket } from "phoenix-socket";
import App from "./views/App";
import React from "react";
import ReactDOM from "react-dom";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
	gtmId: "GTM-5S2SFR9K",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App socket={Socket} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
