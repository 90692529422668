import { dataFromLocalStorage, setDataForCache } from "io/localStorage";
import { refreshCachedOfficeAndLines } from "lib/refreshCachedData";

export const requestLines = async (state, dispatch, action) => {
	const { officeSlug, officeId } = action;
	// const officeSlugCached = localStorage.getItem("officeSlugWeb");
	if (!officeSlug) return;
	if (state.linesv2.loading) return;

	// const { data: linesCached } = dataFromLocalStorage(`lines`);
	// if (linesCached && officeSlug === officeSlugCached) {
	// 	const needRefresh = refreshCachedOfficeAndLines(linesCached);
	// 	if (!needRefresh) {
	// 		const lines = linesCached.data;
	// 		dispatch.linesGotten({ lines, officeSlug, officeId });
	// 		return;
	// 	}
	// }

	dispatch.requestLinesReducer();
	await dispatch.stateCall({
		method: "get",
		path: `/state/${officeSlug}/lines`,
		onSuccess: (lines) => {
			setDataForCache({ data: lines, key: `lines` });
			dispatch.linesGotten({ lines, officeSlug, officeId });
		},
		onFailure: dispatch.linesRequestFailed,
	});
};

export const requestLine = async (state, dispatch, action) => {
	const { officeSlug, lineSlug, lineId } = action;
	if (lineId !== null && state.linesv2.isloading.indexOf(lineId) !== -1)
		return;
	if (lineId !== null && state.linesv2.byId[lineId]) return;
	dispatch.requestLineReducer(action);
	await dispatch.stateCall({
		method: "get",
		path: `/state/${officeSlug}/lines/${lineSlug}`,
		onSuccess: (l) => dispatch.lineGotten({ ...l, officeSlug }),
		onFailure: dispatch.linesRequestFailed,
	});
};
