// @flow
import "antd/dist/antd.css";
import "mapbox-gl/dist/mapbox-gl.css";
import * as Sentry from "@sentry/browser";
import { BrowserRouter } from "react-router-dom";
import { ConfirmationModal } from "views/components/common/ConfirmationModal";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { pathOr } from "ramda";
import { useAuth } from "hooks/useAuth";
import { useCheckTemporaryTickets } from "hooks/useCheckTemporaryTickets";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useHandleToManyRequestBehaivor } from "hooks/useHandleToManyRequestBehaivor";
import i18n from "i18n";

import { useTemporaryTicketsV2 } from "hooks/useSetLocalStorageTickets";
import Breakpoint, {
	BreakpointProvider,
	setDefaultBreakpoints,
} from "react-socks";
import GlobalStyle from "./GlobalStyle";

import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { Loader } from "./components/Loader";
import { Suspense } from "react";
import React, {
	lazy,
	useDispatch,
	useEffect,
	useGlobal,
	withInit,
} from "reactn";

import UIWrapper from "./components/UIWrapper";
import addReactNDevTools from "reactn-devtools";
import setupEffects from "../state/effects";
import setupState from "../state/state";
import theme from "./resources/theme";

const MobileRouter = lazy(() => import("./mobile/Router"));
const Layout = lazy(() => import("./components/Layout/Layout"));
// Debugging
if (process.env.NODE_ENV === "development") {
	addReactNDevTools();
}

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://7dccc3f80f3246c98834420a0c726843@sentry.io/5054890",
		release: `zeroq-webapp@${String(process.env.REACT_APP_GIT_TAG)}`,
		beforeSend(event, hint) {
			const error = hint.originalException;
			// Check if it is an exception, and if so, show the report dialog
			/*
			if (
				event.exception &&
				error &&
				error.message &&
				error.message.match(/^((?!chunk).)*$/i) &&
				error.message.match(/^((?!position).)*$/i)
			) {
				Sentry.showReportDialog({
					eventId: event.event_id,
					lang: "es",
					title: "Parece que estamos teniendo problemas.",
					subtitle: "Nuestro equipo ha sido notificado.",
					subtitle2:
						"Si desea ayudar, cuéntenos qué sucedió a continuación."
				});
			}
			*/
			return event;
		},
	});
}

const initialState = setupState();
const initialReducers = setupEffects();

// const ExtraFooterSpace = ({ children }) => (
// 	<div style={{ marginBottom: "60px" }}>{children}</div>
// );

setDefaultBreakpoints([{ mobile: 0 }, { desktop: 768 }]);

export default withInit(
	initialState,
	initialReducers
)(function App() {
	const { setTickets, setReservations } = useTemporaryTicketsV2();

	const [config] = useGlobal("UIConfig");
	const initSaga = useDispatch("initSaga");
	const { isLoggedInTemporary } = useAuth();
	const [loadOffices] = useGlobal("loadOffices");

	const [language] = useGlobal("language");
	useCheckTemporaryTickets();
	const { features } = useFeatureFlags();
	useEffect(() => {
		if (loadOffices === false) return;
		if (isLoggedInTemporary) {
			setReservations();
			setTickets();
		}
		if (config && config.itemsToHide) return;
		initSaga();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadOffices]);

	useEffect(() => {
		i18n.changeLanguage(language.selected);
	}, [language]);

	const { textToModal, showOfficeMOdal, onClickForToManyRequestHandler } =
		useHandleToManyRequestBehaivor();

	return (
		<I18nextProvider i18n={i18n}>
			<GrowthBookProvider growthbook={features}>
				<BrowserRouter>
					{showOfficeMOdal && (
						<ConfirmationModal
							label={textToModal}
							type={"to_many_requests_error"}
							onClick={onClickForToManyRequestHandler}
						/>
					)}
					<ThemeProvider theme={theme}>
						<UIWrapper>
							<Suspense fallback={<Loader />}>
								<BreakpointProvider>
									<GlobalStyle />

									<Breakpoint mobile only>
										<MobileRouter />
									</Breakpoint>
									<Breakpoint desktop only>
										<Layout />
									</Breakpoint>
								</BreakpointProvider>
							</Suspense>
						</UIWrapper>
					</ThemeProvider>
				</BrowserRouter>
			</GrowthBookProvider>
		</I18nextProvider>
	);
});
