// @flow
import distance from "fast-haversine";
import type { Distance, Office } from "types";

/**
 * Get's the user geological position using the Geolocation Web API
 */
export const getCurrentPosition = (): Promise<?Position> =>
	"geolocation" in navigator
		? new Promise((res, rej) =>
				navigator.geolocation.getCurrentPosition(res, rej)
		  )
		: Promise.resolve(null);

const isNumber = (value: any): boolean =>
	!isNaN(parseFloat(value)) && isFinite(value);

/**
 * Calculates the distance between 2 coordinates.
 * In the future we should use Google's street distance
 * calculator.
 */
export const calculate_distance = (
	position: Position,
	office: Office
): Distance => {
	if (position == null)
		return {
			distance: Infinity,
			unit: "m",
		};
	if (office.lat === 0 && office.lng === 0)
		return {
			distance: Infinity,
			unit: "m",
		};
	if (!(isNumber(office.lat) && isNumber(office.lng)))
		return {
			distance: Infinity,
			unit: "m",
		};
	const from = {
		lat:
			position.coords && position.coords.latitude
				? position.coords.latitude
				: position.lat,
		lon:
			position.coords && position.coords.longitude
				? position.coords.longitude
				: position.lng,
	};
	const to = { lat: office.lat, lon: office.lng };
	const dist = distance(from, to);
	if (dist > 1000)
		return {
			distance: dist,
			unit: "km",
		};
	return {
		distance: dist,
		unit: "m",
	};
};

export const calculateDistance = (position, office) => {
	if (position == null)
		return {
			distance: Infinity,
			unit: "m",
		};
	if (office.lat === 0 && office.lng === 0)
		return {
			distance: Infinity,
			unit: "m",
		};
	if (!(isNumber(office.lat) && isNumber(office.lng)))
		return {
			distance: Infinity,
			unit: "m",
		};
	const from = {
		lat:
			position.coords && position.coords.latitude
				? position.coords.latitude
				: position.lat,
		lon:
			position.coords && position.coords.longitude
				? position.coords.longitude
				: position.lon,
	};
	const to = { lat: office.lat, lon: office.lng };
	const dist = distance(from, to);
	if (dist > 1000)
		return {
			distance: dist,
			unit: "km",
		};
	return {
		distance: dist,
		unit: "m",
	};
};

export const goToMap = (userCoords, officeCoords, travelMode) => {
	if (!userCoords || !officeCoords) return;

	const { userLat, userLng } = userCoords;
	const { offLatitude, offLongitude } = officeCoords;

	const url = `https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${offLatitude},${offLongitude}&travelmode=${travelMode}`;
	return window.open(url, "_blank");
};
