import "firebase/auth";
import { loginWithUUID } from "./login";

import firebase from "firebase/app";
import envConfig from "./config";

const firebaseConfig = envConfig?.firebase;
const start = () => {
	firebase.initializeApp(firebaseConfig);

	const facebook = new firebase.auth.FacebookAuthProvider();
	const google = new firebase.auth.GoogleAuthProvider();
	const microsoft = new firebase.auth.OAuthProvider("microsoft.com");
	microsoft.addScope("openid profile User.Read Directory.AccessAsUser.All");

	return {
		facebook,
		google,
		microsoft,
	};
};

export default start;

export const signIn = (providers) => (provider) =>
	firebase
		.auth()
		.signInWithPopup(providers[provider])
		.then((result) => {
			const user = {
				uid: result.user.uid,
				name: result.user.name,
				email: result.user.email,
			};
			return loginWithUUID(user, provider);
		});
