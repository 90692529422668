export const TICKET_MAX_AGE = 21600000;
export const TICKETS_PER_PAGE = 10;
export const TICKETS_PER_SCREEN = 4;

export const fetchUserTickets = async (state, dispatch) => {
	// assumes no refetching is needed once tickets have been loaded
	if (!state.ticketsv2.loading && state.ticketsv2.all.length === 0) {
		dispatch.fetchUserTicketsReducer();
		dispatch.preferencesService({
			method: "get",
			path: "/tickets?status=active",
			onSuccess: dispatch.userTicketsGotten,
			onFailure: dispatch.userTicketsRequestFailed,
		});
	}
};

export const fetchMoreUserTickets = async (state, dispatch) => {
	const { currentPage, historic } = state.ticketsv2;
	if (historic.ids.length >= currentPage * TICKETS_PER_PAGE) {
		console.log("trying to refetch already fetched");
		return;
	}
	/*
	if (
		state.ticketsv2.historic.byPage[currentPage] &&
		state.ticketsv2.historic.byPage[currentPage].length > 0
	) {
		console.log("trying to refetch already fetched page");
		return;
	}
	*/
	dispatch.fetchUserTicketsReducer({ filter: "historic" });
	dispatch.preferencesService({
		method: "get",
		path: `/tickets?status=history&page=${currentPage}`,
		onSuccess: dispatch.userTicketsGotten,
		onFailure: dispatch.userTicketsRequestFailed,
	});
};

export const userTicketsGotten = async (state, dispatch, action) => {
	const { tickets } = action.data;
	if (tickets) {
		tickets.forEach(
			(t) =>
				new Date() - new Date(t.inserted_at) < TICKET_MAX_AGE &&
				!t.called &&
				dispatch.joinChannel({
					officeSlug: t.office,
					lineId: t.line_id,
				})
		);
		dispatch.userTicketsGottenReducer(action.data);
	}
};
