export const getVisibleLinesByOfficeId = (state, oid) => {
	if (typeof oid === "undefined" || !oid) return [];
	return state
		.filter((l) => !l.virtual && !l.executive)
		.filter((l) => l.office_id === oid || l.officeId === oid)
		.filter((l) => l.type !== "hidden")
		.sort((l0, l1) => l0.priority - l1.priority);
};

export const getVisibleLinesByOfficeSlug = (state, slug) => {
	if (typeof slug === "undefined" || !slug) return [];
	return Object.values(state.bySlug)
		.filter((l) => !l.virtual && !l.executive)
		.filter((l) => l.officeSlug === slug)
		.filter((l) => l.type !== "hidden")
		.sort((l0, l1) => l0.priority - l1.priority);
};

export const getLine = (lines, lineSlug) =>
	lines.filter((l) => l.slug === lineSlug).reduce((d, l) => l, null);

export const selectLine = (lines, lineSlug) => {
	return lines.bySlug[lineSlug];
};

export const selectVisibleLines = (lines) => {
	if (!lines) return [];

	return lines
		.filter((l) => !l?.virtual && !l?.executive && l?.type !== "hidden")
		.sort((l0, l1) => l0.priority - l1.priority);
};

export const filterLines = (lines) => {
	if (!lines) return [];

	return lines.filter((l) => l.type !== "kiosk");
};

export const selectFolders = (lines) => lines.map((l) => l.folder);

export const hiddenFolderEmpty = (lines, folders, showKioskLines) => {
	const groupedLines = lines.reduce((acc, line) => {
		const folderName = line.folder && line.folder.trim();

		if (line.type === "kiosk" && !showKioskLines) {
			return acc;
		}

		if (folderName) {
			if (!acc[folderName]) {
				acc[folderName] = [];
			}
			acc[folderName].push(line);
		} else {
			if (!acc["noFolder"]) {
				acc["noFolder"] = [];
			}
			acc["noFolder"].push(line);
		}
		return acc;
	}, {});

	const existingFolders = Object.keys(groupedLines);

	const filteredFolders = folders.filter((folder) =>
		existingFolders.includes(folder)
	);
	return filteredFolders;
};

export const selectLineById = (lines, lineId) => {
	if (lines.byId[lineId]) return lines.byId[lineId];
	return Object.values(lines.bySlug).reduce(
		(def, l) => (l.id === lineId ? l : def),
		{}
	);
};
