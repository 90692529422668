// @flow

import * as R from "ramda";
import moment from "moment";
import type { InputReserve } from "types";
import type { SyncReducer } from "./types";

export const initReserves: SyncReducer<InputReserve[]> = (
	state,
	_,
	reserves
) => {
	return {
		...state,
		reserves: state.reserves.concat(R.map(evolve, reserves)),
	};
};

type ReserveArgs = {
	reserve: InputReserve,
	office: string,
};
export const newReserve: SyncReducer<ReserveArgs> = (
	state,
	_,
	{ reserve, office }
) => {
	const lens = R.lensProp("reserves");
	const new_reserve = R.pipe(R.mergeRight({ office }), evolve)(reserve);
	return R.over(lens, R.append(new_reserve), state);
};

const parse_date = (date) => moment.utc(date, "YYYY-MM-DDTHH:mm:ss").local();
const evolve = R.evolve<InputReserve>({
	from: parse_date,
	to: parse_date,
});
