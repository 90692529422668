import { message } from "antd";
export const loadUserData = async (state, dispatch, action) => {
	await dispatch.fetchUserReservations();
	dispatch.fetchUserReservationsV1();
	dispatch.fetchUserTickets();
	// dispatch.getPolls();
	dispatch.getFavorites();
	dispatch.getUserPreferences();
};

// special merge in which empty strings are considered
// to be a way to erase the previous values
function merge(obj1, obj2) {
	const answer = { ...obj2 };
	// if props are absent from obj2, use obj1
	for (let key in obj1) {
		if (obj2[key] === null || typeof obj2[key] === "undefined") {
			answer[key] = obj1[key];
			continue;
		}
	}
	return answer;
}

export const updateUserData = async (state, dispatch, action) => {
	if (state.guest) return message.error("User is Guest");

	const { rut, dni } = action;

	const {
		name,
		phone,
		whatsapp,
		token,
		uid,
		email,
		push,
		never_show_popup_notifications,
		onlyPreferences,
	} = merge(state.user, action);

	const body = { uid, token, user: { name, email, phone, rut, dni } };
	// adds rut or dni if set
	if (action[action.type] !== null)
		body.user[action.type] = action[action.type];

	dispatch.userDataUpdateRequested();
	// if (whatsapp !== null) {
	// 	dispatch.preferencesService({
	// 		method: "post",
	// 		path: "/preferences",
	// 		body: { site: "web", notifications: { whatsapp } },
	// 		onSuccess: dispatch.userUpdated,
	// 		onFailure: console.error,
	// 	});
	// }
	if (
		push !== undefined &&
		whatsapp !== undefined &&
		never_show_popup_notifications === undefined
	) {
		dispatch.preferencesService({
			method: "post",
			path: "/preferences",
			body: {
				site: "web",
				notifications: {
					whatsapp,
					push,
					never_show_popup_notifications: false,
				},
			},
			onSuccess: dispatch.userUpdated,
			onFailure: console.error,
		});
	}

	if (
		never_show_popup_notifications !== undefined &&
		push !== undefined &&
		whatsapp !== undefined
	) {
		dispatch.preferencesService({
			method: "post",
			path: "/preferences",
			body: {
				site: "web",
				notifications: {
					whatsapp,
					push,
					never_show_popup_notifications,
				},
			},
			onSuccess: dispatch.userUpdated,
			onFailure: console.error,
		});
	}

	if (onlyPreferences) return;
	dispatch.loginService({
		method: "patch",
		path: "/user",
		body,
		onSuccess: (res) => {
			dispatch.userUpdated(res);
			if (!push && !never_show_popup_notifications)
				message.success("Datos actualizados correctamente ");
		},
		onFailure: dispatch.userUpdateFailed,
	});
};

export const getUserPreferences = (state, dispatch) => {
	dispatch.preferencesService({
		method: "get",
		path: "/preferences",
		onSuccess: (res) => {
			// todo: fix this for more cases

			if (res && !res.data) {
				dispatch.userUpdated({
					data: {
						notifications: {
							whatsapp: "prompt",
							push: false,
							never_show_popup_notifications: false,
						},
					},
				});
				return;
			}

			if (typeof res.data.web === "undefined") {
				dispatch.userUpdated({
					data: {
						notifications: {
							whatsapp: "prompt",
							push: false,
							never_show_popup_notifications: false,
						},
					},
				});
				return;
			}
			dispatch.userUpdated({ data: res.data.web });
		},
		onFailure: (_) =>
			dispatch.userUpdated({
				data: {
					notifications: {
						whatsapp: "prompt",
						push: false,
						never_show_popup_notifications: false,
					},
				},
			}),
	});
};
