import "typeface-montserrat";
import "typeface-roboto";
import { createGlobalStyle } from "styled-components";
import { getWeight } from "views/resources/theme";

const GlobalStyle = createGlobalStyle`
  body, input {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }


  body, 
  .breakpoint__mobile-only {
	height: 100%;
  background: #fff;
  }

  h1,h2,h3,h4,h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: ${getWeight("semibold")};
  }
 
.ant-steps-item-icon {
  margin: 0;
  }

  .ant-modal-wrap {
    display:flex;
    align-items:center;
    z-index: 9000 !important;

  }
  
.ant-modal-root {
  position: absolute;
  z-index: 2000
}
.ant-select-dropdown {
  position: absolute;
  z-index: 2000
}
`;

export default GlobalStyle;
