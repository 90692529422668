import { _produce, removeOne } from "./util.js";
import { addCode } from "lib/tickets";
import { mapReservationsToV2 } from "./reservations/reducers";
import { normalize, schema } from "normalizr";

export const pickReserve = async (state, dispatch, action) => {
	const { block, lineId, meta, meet } = action;
	dispatch.createReserve({
		reserve: {
			from: block.from.toISOString(),
			to: block.to.toISOString(),
			line_id: lineId,
			meta,
			meet,
		},
	});
};

export const createReserve = async (state, dispatch, action) => {
	const onFailure = (error) =>
		dispatch.reserveCreatedFailure({ error, ...action });

	dispatch.apiCall({
		method: "post",
		body: action,
		path: "/user/reserves",
		onSuccess: dispatch.reserveCreatedSuccess,
		onFailure,
	});
};

export const pickReserveSagaReducer = (state, _, action) => ({
	...state,
	reserveQueue: state.reserveQueue.concat({
		isConfirmed: false,
		isCreated: false,
		isOngoing: true,
		line_id: action.lineId,
	}),
});

const reservationSchema = new schema.Entity("reservation");
export const reserveCreatedSuccess = _produce((state, action) => {
	const { reserveQueue } = state;
	const { line_id } = action;

	state.reserveQueue = update(reserveQueue, "line_id", line_id, {
		...action,
		isCreated: true,
	});

	const { entities, result } = normalize(action, reservationSchema);
	state.myReservations.byId[result] = addCode(entities.reservation[result]);
	state.myReservations.current.push(result);
	state.myReservations.loading = false;
});
export const reserveCreatedInit = _produce((state, action) => {
	state.myReservations.loading = true;
});
export const reserveCreatedFinish = _produce((state, action) => {
	state.myReservations.loading = false;
});

export const reserveCreatedFailure = (state, dispatch, action) => {
	const lineId = action.reserve.lineId;
	const errors =
		action.error &&
		action.error.response.data.errors.from.map((err) => errorsByKey[err]);

	errors.forEach((err) => dispatch.alert(new Error(err)));

	dispatch.reserveCreatedFailureReducer({ lineId, errors });
	//dispatch.deleteReserve();
	dispatch.finishReserveSaga(action.reserve);
	dispatch.reserveCreatedFinish();
};

export const reserveCreatedFailureReducer = (state, _, action) => ({
	...state,
	reserveQueue: update(state.reserveQueue, "line_id", action.lineId, {
		isOngoing: false,
		isError: true,
		error: action.errors,
	}),
});

/*
 *
Payload:

{
	confirmed: true
	from: "2020-11-13T11:00:00.000000"
	id: 176732
	line_id: 7815
	to: "2020-11-13T11:15:00.000000"
	user_id: 348128
}
*/
export const reserveConfirmed = _produce((state, action) => {
	const { reserveQueue, reserves, lines } = state;
	const { line_id, id } = action;
	const line = lines.filter((l) => l.id == line_id).reduce((_, l) => l, {});
	state.reserves = reserves.concat(
		reserveQueue
			.filter((r) => r.line_id == line_id)
			.reduce((p, c) => ({ ...c, ...p, ...line, id: c.id }), action)
	);
	state.reserveQueue = reserveQueue.map((rq) => {
		if (rq.line_id == line_id)
			return {
				...rq,
				...action,
				...line,
				isConfirmed: true,
				isOngoing: true,
				id: rq.id,
			};
		return rq;
	});
	state.myReservations.confirmed.push(id);
	// These are reservations that do not match with any
	// user reservations.
	// We still preserve the confirmed id in the case a
	// reservation might not had been loaded yet.
	if (!state.myReservations.byId[id]) return;
	state.myReservations.byId[id].confirmed = true;
	removeOne(state.myReservations.created, id);
});

export const reserveTimeout = _produce((state, action) => {
	const { lineId, error } = action;
	if (
		state.reserveQueue.some(
			(rq) => rq.line_id == lineId && !rq.confirmed && !rq.isCreated
		)
	)
		state.notifications.push({ type: "alert", ...error });
});

export const finishReserveSaga = async (state, dispatch, action) => {
	dispatch.finishReserveSagaReducer(action);
};

export const finishReserveSagaReducer = (state, _, action) => ({
	...state,
	reserveQueue: update(state.reserveQueue, "line_id", action.lineId, {
		isOngoing: false,
	}),
});

export const deleteReserve = (state, dispatch, action) => {
	const { reserveId } = action;
	dispatch.apiCall({
		method: "delete",
		path: `/user/reserves/${reserveId}`,
		onSuccess: dispatch.reservationDeleteSuccess,
		onFailure: dispatch.reservationDeleteFailure,
	});
};

export const reservationDeleteSuccess = _produce((state, action) => {
	const { id } = action;
	state.reserves = state.reserves.map((r) => {
		if (r.id === id)
			return {
				...r,
				deleted: true,
			};
		return r;
	});
	state.myReservations.byId[id].deleted = true;
	state.notifications.push({
		type: "success",
		message: "Reserva eliminada correctamente",
	});
});

export const reservationDeleteFailure = (state, dispatch, action) => {
	dispatch.alert(new Error(action));
	//dispatch.reservationDeleteFailureReducer();
};

// TODO: simplify state updates. Make more readable
const update = (array, key, value, newElem) =>
	array
		.filter((e) => e[key] !== value)
		.concat(
			array
				.filter((e) => e[key] === value)
				.reduce((p, c) => ({ ...c, ...p }), newElem)
		);

export const getReservations = async (state, dispatch, action) => {
	const office = state.offices[action];
	if (state.reservations.isLoading) return;
	dispatch.getReservationsReducer();
	//const yesterday = new Date();
	dispatch.apiCall({
		method: "get",
		path: `/offices/${office.id}/reserves/?from=2020-09-14`,
		onSuccess: dispatch.reservationsGetSuccess,
		onFailure: dispatch.reservationsGetFailure,
	});
};

// request all reservations made to an OFFICE
export const requestReservations = async (state, dispatch, action) => {
	const { officeId } = action;
	dispatch.getReservationsReducer();
	if (state.reservations.isLoading) return;
	//const yesterday = new Date();
	dispatch.apiCall({
		method: "get",
		path: `/offices/${officeId}/reserves/?from=2020-09-14`,
		onSuccess: dispatch.reservationsGetSuccess,
		onFailure: dispatch.reservationsGetFailure,
	});
};

export const getReservationsReducer = _produce((state, action) => {
	state.reservations.isLoading = true;
});

export const reservationsGetFailure = async (state, dispatch, action) => {
	dispatch.alert(JSON.stringify(action));
};

const reservationsSchema = [new schema.Entity("reservations")];
export const reservationsGetSuccess = _produce((state, action) => {
	state.reservations.byId =
		normalize(action, reservationsSchema).entities.reservations || {};
	state.reservations.isLoading = false;
});

export const pickReserveSagaNotFoundException = (state, _, action) => ({
	...state,
	notifications: state.notifications.concat(action),
});

export const getReserveById = (id, state) => {
	return state.filter((r) => String(r.id) === id);
};

export const getLineBySlug = (slug, state) =>
	state.filter((r) => r.slug === slug);

export const getOfficeBySlug = (slug, state) =>
	Object.values(state).filter((o) => o.slug === slug);

// TODO:move to errors file
const errorsByKey = {
	"Reserve already exists":
		"Ya existe una reserva en este bloque horario. Por favor, selecciona otra fecha.",
	"Reserve in the past": "No se puede reservar una hora en el pasado",
};
