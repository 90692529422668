import "./line";
import "./listeners";
import * as R from "ramda";
import * as apiMiddleware from "./api.js";
import * as channelReducers from "./channel";
import * as favoritesReducers from "./favorites";
import * as languageReducers from "./language";
import * as lineReducers from "./line";
import * as linesReducers from "./lines";
import * as listenerReducers from "./listeners";
import * as middlewares from "./middlewares.js";
import * as notificationsReducers from "./notifications";
import * as officeReducers from "./office";
import * as officeReducers2 from "./offices";
import * as pollReducers from "./poll";
import * as reduxers from "./reducers.js";
import * as reservationsReducers from "./reservations";
import * as reserveMiddleware from "./reserves.js";
import * as reserveReducers from "./reserve";
import * as ticketReducers from "./ticket";
import * as ticketsReducers from "./tickets";
import * as uiReducers from "./ui";
import * as userReducers from "./user";
import * as userReducers2 from "./users";
import { pushNotificacionSubscription } from "lib/subscribeToPushNotifications";

import * as searchReducers from "./search";

import { addCallback, addReducers } from "reactn";
import { createBrowserHistory } from "history";
import { fromLocalStorage, setLocalStorage } from "../io/localStorage";
import { initSocket } from "network/socket";
import startFirebase from "network/firebase";

export const State = {
	user: {
		position: {},
		guest: true,
		isLoading: false,
		error: {},
		notifications: {},
	},
	defaultForms: {
		forms: {
			type: "office",
			questions: [
				{
					_id: "618d873c5650eb29d844f1e4",
					officeId: 1777,
					fieldType: "name",
					question: "Nombre",
					answer: "",
					limit: 32,
					required: true,
					validCharacter: "[aA-zZ]",
					validMessage: "Debe ingresar un nombre válido",
					configId: {},
					active: true,
					created_at: "2021-11-11T21:12:28.432Z",
					__v: 0,
				},
				{
					_id: "618d873c5650eb29d834f1e3",
					officeId: 1777,
					fieldType: "tel",
					question: "Teléfono",
					answer: "",
					limit: 60,
					required: true,
					validCharacter: "^[0-9]*$",
					validMessage: "Debe ingresar un numero de teléfono válido",
					configId: {},
					active: true,
					created_at: "2021-11-11T21:12:28.431Z",
					__v: 0,
				},
				{
					_id: "618d873c5650eb29d14f1e5",
					officeId: 1777,
					fieldType: "email",
					question: "Email",
					answer: "",
					limit: 60,
					required: true,
					validCharacter: "[aA-zZ]",
					validMessage: "Debe ingresar un correo válido",
					configId: {},
					active: true,
					created_at: "2021-11-11T21:12:28.433Z",
					__v: 0,
				},
			],
		},
		defaultFormsPayload: null,
	},
	toManyRequestsParams: {
		toManyRequestError: false,
		numberOfTry: 0,
	},
	messages: [],
	socketio: "disconnected",
	offices: {},
	officesv2: {
		bySlug: {},
		loading: [],

		isLoading: false,
		errorMessage: null,
	},
	categories: {
		byId: {},
		isLoading: false,
		errorMessage: null,
	},
	search: {
		searchValue: "",
		resultsQuantity: 0,
		filters: {},
		category: {},
		districts: [],
	},
	called: {
		ids: [],
	},
	channels: {
		byId: {},
	},
	tickets: [],
	ticketsv2: {
		byId: {},
		historic: {
			byPage: {},
			total: null,
			loading: false,
			ids: [],
			error: null,
		},
		//queue: {},
		active: [],
		all: [],
		// deprecated
		called: [],
		// deprecated
		current: [],
		timeout: NaN,
		isLoading: false,
		loading: false,
		isResolved: false,
		attending: null,
		error: "",
		currentPage: 1,
	},
	UIConfig: {
		backArrowpath: "",
		hideBackArrow: false,
		hideFooter: false,
		hideHeader: false,
		hideHomeBreadCrumb: false,
		hideLineCardFooter: false,
		hideLineInfo: false,
		hideOfficeFooter: false,
		hideOfficeLabel: false,
		hideReservationBreadCrumb: false,
		hideReserveButton: false,
		hideTicketsButton: false,
		hideSideBarIcon: false,
		hideFavoritesButton: false,
		hideFaqsRedirect: false,
		hideTicketFormHeaderMessage: false,
		hideOfficeDetailText: false,
	},
	lines: [],
	linesv2: {
		bySlug: {},
		byId: {}, // this is required because call:created only returns the line_id, not the slug
		loading: false,
		isloading: [],
		error: "",
	},
	newTicket: { ticket: {} },
	reserves: [],
	reserveQueue: [],
	forms: {
		reservationForms: [],
		ticketForms: {},
		ticketFields: [],
		fields: [],
		payload: {},
		loading: true,
		error: null,
	},
	reservations: {
		byId: {},
		isLoading: false,
		errorMessage: null,
	},
	reservationsv2: {
		byId: {},
		queued: {
			ids: [],
			isFetching: false,
			error: null,
		},
		created: {
			ids: [],
			isFetching: false,
			error: null,
		},
		confirmed: {
			ids: [],
			isFetching: false,
			error: null,
		},
		current: [],
		timeouts: [],
	},
	reservationsv3: {
		dataTimeblocks: null,
		oldIdReservation: null,
	},
	calls: [],
	settings: {
		byOfficeSlug: {},
		byLineSlug: {},
		isFetching: false,
		error: null,
	},
	socket: undefined,
	moduleOnAttention: [],
	notifications: [
		/*
		{
			type: "call",
			office: {
				category_id: 15
			},
			line: "Line",
			ticket: {
				prefix: "T",
				number: 1,
				from: new Date().toISOString(),
				to: new Date().toISOString()
			},
			call: { modulo: "MM" }
		}
		*/
	],
	history: createBrowserHistory(),
	guest: false,
	polls: [],
	myReservations: {
		byId: {},
		respald: {},
		// reservations are only indexed by slug when an id has not yet been assigned
		bySlug: {},
		queued: [],
		created: [],
		confirmed: [],
		current: [],
		morituri: [],
		deleted: [],
		loading: false,
		errorMessage: "",
	},
	timeblocks: {
		byId: {},
		isLoading: false,
		// todo: deprecate
		errorMessage: "",
		error: {},
	},
	unavailableDays: {
		byLineId: {},
		loading: false,
		error: "",
	},
	favorites: {
		byId: {},
		isLoading: false,
		errorMessage: "",
		offices: [],
		lines: [],
	},
	listeners: {},
	officeHours: {
		byId: {},
		isLoading: false,
		errorMessage: "",
	},
	customerParams: {},
	ui: {},
	procedureByOffices: {
		isLoading: false,
		procedureList: [],
		offices: [],
	},
	language: {
		selected: "es",
	},
	isLoading: false,
};

const setupState = () => {
	const socket = initSocket();
	const providers = startFirebase();
	const reducers = R.mergeAll([
		middlewares,
		reduxers,
		listenerReducers,
		officeReducers,
		reserveReducers,
		reserveMiddleware,
		reservationsReducers,
		apiMiddleware,
		ticketReducers,
		ticketsReducers,
		userReducers,
		userReducers2,
		channelReducers,
		lineReducers,
		officeReducers2,
		pollReducers,
		uiReducers,
		favoritesReducers,
		linesReducers,
		notificationsReducers,
		searchReducers,
		languageReducers,
		{ initSaga },
		// { refreshUser },
	]);
	addReducers(reducers);
	addCallback(setLocalStorage);
	return R.mergeAll([State, fromLocalStorage(), { socket, providers }]);
};

const initSaga = async (state, dispatch) => {
	/*
	dispatch.getCategoriesX();
	dispatch.getTickets();
	dispatch.fetchUserReservationsV1();
	dispatch.fetchUserReservations();
	dispatch.getOffices();
	dispatch.getPolls();
	*/
	//await Promise.all([offices, tickets, polls, reservations2, reservationsv2]);

	// TODO: Delete in next release -----------------------------------------------
	const officesCached = JSON.parse(localStorage.getItem("offices"));
	if (officesCached && officesCached.data) {
		const officesSlug = Object.keys(officesCached.data);
		officesSlug.forEach((slug) => {
			localStorage.removeItem(slug);
			localStorage.removeItem(`${slug}:lines`);
		});
	}
	// -----------------------------------------------

	const temporaryUser = R.pathOr(false, ["user", "temporal"], state);
	const aGuest = R.pathOr(false, ["user", "guest"], state);
	dispatch.getCategoriesX();

	if (!temporaryUser) {
		if (aGuest === false) dispatch.fetchUserTickets();
	}
	if (!temporaryUser) {
		if (aGuest === false) dispatch.getUserPreferences();
	}
	dispatch
		.getOffices()
		.then(dispatch.askUserPosition)
		.then(!temporaryUser ? dispatch.fetchUserReservations : null)
		.then(!temporaryUser ? dispatch.fetchUserReservationsV1 : null)
		//.then(dispatch.getReserves)
		.catch((e) => {
			// if (e instanceof Error) dispatch.alert(e);
			console.error("Saga error:", e);
		});
};

// const refreshUser = async (state, dispatch) => {
// 	dispatch.getUserPreferences();
// };

export default setupState;
