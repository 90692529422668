import { DateTime } from "luxon";
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";

export const sumDays = (fecha, dias) => {
	fecha.setDate(fecha.getDate() + dias);
	return fecha;
};

export const addDays = (fecha, dias) => {
	let newDate = new Date(fecha);
	newDate.setDate(newDate.getDate() + dias);
	return newDate;
};

export const dateToString = (date, language = "es") => {
	if (!date) return "";
	const jsDate = moment(date).toDate();

	const day = DateTime.fromJSDate(jsDate)
		.setLocale(language)
		.toFormat(language === "es" ? "cccc d 'de' LLLL" : "cccc d, LLLL");
	const charToReplace = day.split("")[0];
	const newChar = charToReplace.toUpperCase();

	return day.replace(charToReplace, newChar);
};

export const getTemporaryInsertedAt = (token) => {
	if (!token) return;
	const { iat } = jwtDecode(token);
	const dateNow = new Date();
	const tokenIa = moment.unix(iat);
	const tokenTime = new Date(tokenIa);
	// const tokenTime = new Date("2021-09-03T05:03:55.030Z");
	const diffTime = Math.abs(tokenTime - dateNow);
	var minutes = Math.floor(diffTime / 60000);
	const hour = Math.floor(minutes / 60);
	return hour;
};

export const isTwoHoursDiff = (date) => {
	if (!date) return;
	const dateNow = new Date();
	const tokenTime = new Date(date);
	// const tokenTime = new Date("2021-09-03T05:03:55.030Z");
	const diffTime = Math.abs(tokenTime - dateNow);
	var minutes = Math.floor(diffTime / 60000);
	const hour = Math.floor(minutes / 60);
	if (hour < 3) return false;
	return true;
};

export const parseTime = (date, tz) => (time) => {
	return moment.tz(date.format().split("T")[0] + "T" + time, tz);
};

export const parseDatetime = (datetime, tz) => {
	return moment.tz(datetime, tz).clone();
};

export const formatTime = (date, timezone) =>
	moment.tz(date, timezone).clone().format("HH:mm");

export const formatDate = (date, timezone, format) => {
	const tz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
	return moment
		.tz(date, tz)
		.clone()
		.format(format || "ll");
};

// expects non timezoned input
export const formatDatetime = (datetime, timezone) => {
	const tz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
	if (!datetime || !tz) return null;
	const time = moment
		.utc(datetime, "YYYY-MM-DDTHH:mm:ss.SSZZ")
		.tz(tz)
		.clone();
	return `${time.format("DD/MM/YYYY")} a las ${time.format("LTS")}`;
};

const addZeroPrefix = (s) => ("0" + String(s)).substr(-2);
// returns datetime according to the user agent's configured time zone.
export const formatNativeDate = (date) => {
	const d = moment.isMoment(date) ? date.toDate() : date;
	return [
		d.getFullYear(),
		addZeroPrefix(d.getMonth() + 1),
		addZeroPrefix(d.getDate()),
	].join("-");
};

export const addDate = (base, addend) => {
	//if (typeof base.getTime === "function")
	//	return new Date(base.getTime() + addend.getTime());
	return new Date(base.getTime() + addend);
};

export const isBefore = (date0, date1) => date0.getTime() < date1.getTime();

export const minDiff = (date0, minDiff, timezone) => {
	if (!date0) return;
	const end = moment(date0).tz(timezone);
	var now = moment(new Date()).tz(timezone);
	var duration = moment.duration(now.diff(end));
	var minutes = duration.asMinutes();
	const result = minutes >= minDiff;
	return result;
};

export const getMonthsDifference = (startDate, endDate) => {
	return (
		endDate.getMonth() -
		startDate.getMonth() +
		12 * (endDate.getFullYear() - startDate.getFullYear())
	);
};

export const antLocale = {
	lang: {
		locale: "es_ES",
		placeholder: "Selecciona una fecha",
		rangePlaceholder: ["Inicio", "Término"],
		today: "Hoy",
		now: "Ahora",
		backToToday: "Volver a hoy",
		ok: "Ok",
		clear: "Clear",
		month: "Mes",
		year: "Año",
		timeSelect: "Selecciona una hora",
		dateSelect: "Selecciona una fecha",
		monthSelect: "Choose a month",
		yearSelect: "Choose a year",
		decadeSelect: "Choose a decade",
		yearFormat: "YYYY",
		dateFormat: "M/D/YYYY",
		dayFormat: "D",
		dateTimeFormat: "M/D/YYYY HH:mm:ss",
		monthFormat: "MMMM",
		monthBeforeYear: true,
		previousMonth: "Previous month (PageUp)",
		nextMonth: "Next month (PageDown)",
		previousYear: "Last year (Control + left)",
		nextYear: "Next year (Control + right)",
		previousDecade: "Last decade",
		nextDecade: "Next decade",
		previousCentury: "Last century",
		nextCentury: "Next century",
	},
	timePickerLocale: {
		placeholder: "Select time",
	},
	dateFormat: "YYYY-MM-DD",
	dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
	weekFormat: "YYYY-wo",
	monthFormat: "YYYY-MM",
};
