import { CheckCircleFilled, RiseOutlined } from "@ant-design/icons";
import { MODAL_TYPES } from "lib/helpers/toManyRequestsTextDefinitions";
import { Modal as MobileModal } from "antd-mobile";
import {
	StyledContainer,
	StyledDiv,
	StyledModal,
} from "./ConfirmationModal.styles";
import { Button, Typography } from "antd";
import React from "react";
import logos from "views/resources/logos/SVG/";

const { Text } = Typography;

export const ConfirmationModal = ({ label, type, onClick }) => {
	if (type === MODAL_TYPES.TO_MANY_REQUESTS_ERROR) {
		return (
			<StyledContainer>
				<StyledModal>
					<StyledDiv>
						<RiseOutlined
							style={{ fontSize: 40, color: "#195FA4" }}
						/>
						<Text>{label}</Text>
						<Button onClick={onClick}>Recargar</Button>
					</StyledDiv>
				</StyledModal>
			</StyledContainer>
		);
	}
	return (
		<StyledContainer>
			<StyledModal>
				<StyledDiv>
					<CheckCircleFilled
						style={{ fontSize: 32, color: "#195FA4" }}
					/>
					<Text>{label}</Text>
				</StyledDiv>
			</StyledModal>
		</StyledContainer>
	);
};
