// @flow

import {
	doLoginAsGuest,
	doLoginWithPass,
	doLoginWithProvider,
	doSignUpWithPass,
	doTemporaryLogin,
	logout,
	doUpdateTemporaryUser,
	doLoginWithUserData,
} from "./login";
import { findLineByReserve, findLineByTicket, getLine, getLines } from "./line";
import {
	getCategories,
	getHourBlock,
	getHourBlock2,
	getHourBlocks,
	getOfficeState,
	getOffices,
	leaveOfficeChannel,
} from "./office";
import { getPolls, submitPoll } from "./poll";
import {
	getReserve,
	getReserves,
	getTicket,
	waitingForReserveConfirmation,
} from "./ticket";
import {
	getUserPosition,
	registerDni,
	registerRut,
	updateUserInfo,
} from "./user.js";

import { changeLanguage } from "./language";

/**
 * Adds the dispatch functions to the global state.
 * @warning Impure!
 */
const setupEffects = () => ({
	getTicket,
	getLines,
	getLine,
	getOfficeState,
	getOffices,
	leaveOfficeChannel,
	getCategories,
	getHourBlocks,
	getHourBlock,
	getHourBlock2,
	getReserve,
	getReserves,
	getUserPosition,
	doLoginWithPass,
	doTemporaryLogin,
	doUpdateTemporaryUser,
	doLoginWithProvider,
	doSignUpWithPass,
	doLoginAsGuest,
	findLineByTicket,
	findLineByReserve,
	logout,
	registerRut,
	registerDni,
	updateUserInfo,
	getPolls,
	submitPoll,
	waitingForReserveConfirmation,
	doLoginWithUserData,
	changeLanguage,
});

export default setupEffects;
