import { _produce } from "../util.js";
import { normalize, schema } from "normalizr";
import { values } from "ramda";
import { selectLineById } from "./selectors";

export const requestLinesReducer = _produce((state, _) => {
	state.linesv2.loading = true;
});

const linesSchema = [new schema.Entity("lines")];
export const linesGotten = _produce((state, action) => {
	const { lines, officeSlug, officeId } = action;

	Object.values(lines)
		.filter((line) => !state.lines.some((l) => l.id === line.id))
		.forEach((line) => state.lines.push({ ...line, officeSlug, officeId }));

	Object.values(lines).forEach(
		(line) =>
			(state.linesv2.bySlug[line.slug] = {
				...line,
				officeSlug,
				officeId,
			})
	);

	const { entities, result } = normalize(Object.values(lines), linesSchema);
	result.forEach(
		(lid) =>
			(state.linesv2.byId[lid] = { ...entities.lines[lid], officeSlug })
	);
	state.linesv2.loading = false;
});

export const requestLineReducer = _produce((state, action) => {
	const { officeSlug, lineSlug, lineId } = action;
	state.linesv2.isloading.push(lineId);
});

export const lineGotten = _produce((state, line) => {
	state.linesv2.isloading = removeItemOnce(state.linesv2.isloading, line.id);
	state.linesv2.byId[line.id] = line;
	state.linesv2.bySlug[line.slug] = line;
});

export const updateLineField = _produce((state, action) => {
	const { field, value, lineId, nested } = action;
	const line = selectLineById(state.linesv2, lineId);
	if (!line.slug) return;
	if (nested) {
		state.linesv2.bySlug[line.slug][field][nested] = value;
		state.linesv2.byId[lineId][field][nested] = value;
		return;
	}
	state.linesv2.bySlug[line.slug][field] = value;
	state.linesv2.byId[lineId][field] = value;
});

function removeItemOnce(arr, value) {
	var index = arr.indexOf(value);
	if (index > -1) {
		arr.splice(index, 1);
	}
	return arr;
}

export const linesRequestFailed = _produce((state, action) => {
	state.linesv2.error = action;
	console.log("Action failed", action);
	state.linesv2.loading = false;
	console.error(action);
});
