import { complement, equals, pathOr } from "ramda";
import { userReducer } from "../state/user";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
const notEq = complement(equals);

let USER = {};
let TICKETS = {};
export const setLocalStorage = (globalState) => {
	try {
		const { user } = globalState;
		if (notEq(USER, user)) {
			const userToken = pathOr(null, ["token"], user);
			userToken
				? Cookies.set("zq_auth_token", userToken, {
						expires: 15,
						domain: "zeroq.cl",
						path: "/",
				  })
				: Cookies.remove("zq_auth_token");
			localStorage.setItem(
				"user_data",
				JSON.stringify({
					...user,
					id: user.user_id,
				})
			);
			USER = user;
		}
		return null;
	} catch (error) {
		console.log("Error at setLocalStorage");
	}
};
export const setDataForCache = ({ data, key }) => {
	const dataToString = JSON.stringify({
		inserted_at: new Date(),
		data,
	});

	localStorage.setItem(key, dataToString);

	return null;
};

export const removeTemporaryTickets = (type) => {
	if (type === "refresh") {
		return localStorage.removeItem("temporary_reservations");
	}
	if (type === "removeTicket") {
		return localStorage.removeItem("temporary_tickets");
	}

	localStorage.removeItem("temporary_tickets");
	localStorage.removeItem("temporary_reservations");
};

export const setTicketLocalStorage = (tickets, type) => {
	try {
		if (type === "reservations") {
			const reserArr = tickets || [];
			let arr = [];
			if (reserArr) arr.push(reserArr);
			let existReservations = JSON.parse(
				localStorage.getItem("temporary_reservations")
			);

			if (existReservations) {
				existReservations.tickets &&
					existReservations.tickets.push({ ...reserArr });

				localStorage.setItem(
					"temporary_reservations",
					JSON.stringify({
						...existReservations,
					})
				);
				return;
			} else {
				localStorage.setItem(
					"temporary_reservations",
					JSON.stringify({
						tickets: [...arr],
					})
				);
			}
			return;
		}
		const ticketsArr = tickets || [];
		let arr = [];
		if (ticketsArr) arr.push(ticketsArr);
		let existsLocalTicket = JSON.parse(
			localStorage.getItem("temporary_tickets")
		);

		if (existsLocalTicket) {
			existsLocalTicket.tickets &&
				existsLocalTicket.tickets.push({ ...ticketsArr });

			localStorage.setItem(
				"temporary_tickets",
				JSON.stringify({
					timeStamp: new Date(),
					...existsLocalTicket,
				})
			);
			return;
		} else {
			localStorage.setItem(
				"temporary_tickets",
				JSON.stringify({
					timeStamp: new Date(),
					tickets: [...arr],
				})
			);
		}

		return null;
	} catch (error) {
		console.log("error at setTicketLocalStorage");
	}
};

const validateJson = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		//Error
		//JSON is not okay
		return false;
	}

	return true;
};

export const fromLocalStorage = () => {
	const defaultUser = userReducer();
	try {
		const userData = JSON.parse(
			localStorage && validateJson(localStorage.getItem("user_data"))
				? localStorage.getItem("user_data")
				: "{}"
		);
		const jwtData =
			userData && userData.token ? jwtDecode(userData.token) : {};

		return {
			user: { ...defaultUser, ...jwtData.user, ...userData },
		};
	} catch (e) {
		console.log("error at fromLocalStorage");
		return { user: defaultUser };
	}
};

export const dataFromLocalStorage = (key) => {
	const data = JSON.parse(
		localStorage && validateJson(localStorage.getItem(key))
			? localStorage.getItem(key)
			: "{data: false}"
	);

	return {
		data,
	};
};

export const ticketsFromLocalStorage = () => {
	try {
		const userTickets = JSON.parse(
			localStorage.getItem("temporary_tickets")
				? localStorage.getItem("temporary_tickets")
				: "[]"
		);

		return {
			userTickets: userTickets.tickets || [],
		};
	} catch (error) {
		console.log("Error at ticketsFromLocalStorage");
	}
};
export const ticketsFromLocalStorageToCheck = () => {
	try {
		const userTickets = JSON.parse(
			localStorage.getItem("temporary_tickets")
				? localStorage.getItem("temporary_tickets")
				: "[]"
		);

		return {
			...userTickets,
		};
	} catch (error) {
		console.log("error at ticketsFromLocalStorageToCheck");
	}
};
export const reservationsFromLocalStorage = () => {
	try {
		const userReservations = JSON.parse(
			localStorage.getItem("temporary_reservations")
				? localStorage.getItem("temporary_reservations")
				: "[]"
		);

		return {
			userReservations: userReservations.tickets || [],
		};
	} catch (error) {
		console.log("error at reservationsFromLocalStorage");
	}
};
