// @flow

import * as R from "ramda";
import { State } from "./state";
import { _produce } from "./util";
import { enableES5, produce } from "immer";
import type { SyncReducer } from "./types";
import type { User } from "../types";

enableES5();

// wtf
const DEFAULT = {
	name: undefined,
	email: undefined,
	uid: undefined,
	dni: undefined,
	token: undefined,
	rut: undefined,
	position: {},
	isLoading: false,
};

export const userReducer: SyncReducer<> = (state) => {
	return R.assoc("user", DEFAULT, state);
};

export const setUser: SyncReducer<User> = _produce((state, user) => {
	console.log("SET USER", user);
	state.user.email = user.email;
	state.user.name = user.name;
	state.user.rut = user.rut || "";
	state.user.token = user.token || "";
	state.user.uid = user.uid;
	state.user.user_id = user.user_id;
	state.user.dni = user.dni;
	state.user.isLoading = !!user.isLoading;
	state.user.error = user.error;
	if (user.meta && user.meta.temporal)
		state.user.temporal = user.meta.temporal;
	state.user.position = {};
	state.user.guest = user.guest;
	state.user.type = user.type;
	state.user.phone = user.phone ? user.phone : "";
	if (user.id) state.user.user_id = user.id;
});

export const unsetUser: SyncReducer<> = _produce((state) => {
	const initialState = { ...State };
	state.user = initialState.user;
	state.myReservations = initialState.myReservations;
	state.reservationsv2 = initialState.reservationsv2;
	state.tickets = initialState.tickets;
	state.ticketsv2 = initialState.ticketsv2;
	state.favorites = initialState.favorites;
	state.polls = [];
});

export const updateUser: SyncReducer<User> = (state, _, user) => {
	return R.mergeDeepRight(state, { user });
};

export const updateUserInfo: SyncReducer<User> = (state, _, user) => {
	return R.mergeDeepRight(state, { user });
};

export const updateUserPosition: SyncReducer<Position> = (state, _, pos) => {
	return R.assocPath(["user", "position"], pos, state);
};

export const setGuest = _produce((state, guest_status) => {
	state.guest = guest_status;
	state.user.guest = guest_status;
});

export const askUserPosition = async (state, dispatch, action) => {
	if (!("geolocation" in navigator)) {
		dispatch.askUserPositionFailure();
		return;
	}
	navigator.geolocation.getCurrentPosition(
		dispatch.askUserPositionSuccess,
		dispatch.askUserPositionFailure
	);
};

export const askUserPositionSuccess = (state, dispatch, action) => {
	dispatch.askUserPositionSuccessReducer(action);
	dispatch.calculateOfficeDistances(action);
};

export const askUserPositionSuccessReducer = _produce((state, action) => {
	state.user.position = {
		lat: action.coords.latitude,
		lon: action.coords.longitude,
	};
});

export const askUserPositionFailure = _produce((state, action) => {
	state.user.position = {
		errorMessage: action,
	};
});
