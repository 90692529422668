import axios from "axios";

import envConfig from "./config";

const { login } = envConfig;

const loginDinamicUrl = login;

export const loginWithPass = async (user, password) => {
	const response = await axios
		.post(`${loginDinamicUrl}/password`, {
			user,
			password,
		})
		.catch((e) => {
			return e.response;
		});
	return response.data;
};

export const loginWithToken = async (user, token) => {
	const response = await axios.post(loginDinamicUrl, { user, token });
	return response.data.data;
};

export const loginWithUUID = async (user, provider) => {
	const endpoint = provider === "google" ? `/${provider}` : "/user";
	const response = await axios.post(`${loginDinamicUrl}${endpoint}`, {
		uid: user.uid,
		user,
	});
	return response.data.data;
};

export const updateUser = async (user, token) => {
	const response = await axios.patch(`${loginDinamicUrl}/user`, {
		uid: user.uid,
		token,
		user: {
			email: user.email,
			name: user.name,
			rut: user.rut,
			dni: user.dni,
		},
	});
	return response.data.data;
};

// export const signUpWithPass = ({ email, password, name }) => {
// 	return axios
// 		.post(`${loginDinamicUrl}/signup`, {
// 			user: email,
// 			password,
// 			name,
// 		})
// 		.then((r) => r.data.data)
// 		.catch((r) => r.response.data);
// };

export const signUpWithPass = async ({ email, password, name }) => {
	//FIXME: when the temporary user service is in production
	const response = await axios
		.post(`${loginDinamicUrl}/signup`, {
			user: email,
			password,
			name,
		})
		.catch((e) => {
			return e.response;
		});
	return response.data;
};

export const temporaryLogin = async ({
	captchaToken,
	sessionId,
	email,
	phone,
	name,
	rut,
	dni,
}) => {
	//FIXME: when the temporary user service is in production
	const response = await axios
		.post(`${loginDinamicUrl}/temporaryV2`, {
			captchaToken,
			sessionId,
			email,
			phone,
			name,
			rut,
			dni,
		})
		.catch((e) => {
			return e.response;
		});
	return response.data;
};
export const updateTemporaryUser = async ({
	token,
	email,
	phone,
	name,
	rut,
	dni,
}) => {
	//FIXME: when the temporary user service is in production
	const response = await axios
		.put(`${loginDinamicUrl}/temporary`, {
			token,
			email,
			phone,
			name,
			rut,
			dni,
		})
		.catch((e) => {
			return e.response;
		});
	return response.data;
};
