import {
	bookingEN,
	commonEN,
	dinamicFormEN,
	errorsEN,
	waitingRoomEN,
} from "./locales/en";
import {
	bookingES,
	commonES,
	dinamicFormES,
	errorsES,
	waitingRoomES,
} from "./locales/es";
import {
	bookingPT,
	commonPT,
	dinamicFormPT,
	errorsPT,
	waitingRoomPT,
} from "./locales/pt";
import i18n from "i18next";

import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
	resources: {
		es: {
			common: commonES,
			booking: bookingES,
			dinamicForm: dinamicFormES,
			errors: errorsES,
			waitingRoom: waitingRoomES,
		},
		en: {
			common: commonEN,
			booking: bookingEN,
			dinamicForm: dinamicFormEN,
			errors: errorsEN,
			waitingRoom: waitingRoomEN,
		},
		pt: {
			common: commonPT,
			booking: bookingPT,
			dinamicForm: dinamicFormPT,
			errors: errorsPT,
			waitingRoom: waitingRoomPT,
		},
	},
	defaultNS: "common",
	lng: "es",
	fallbackLng: "es",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
