// @flow

import * as R from "ramda";
import { _produce } from "./util.js";
import type { Line } from "types";
import type { SyncReducer } from "./types";

type OfficeLines = {
	office_slug: string,
	lines: Line[],
};

/**
 * Set's the lines for a given office.
 * @params {Object} args
 * @params {string} args.office_slug - The slug of the office.
 * @params {Object[]} args.lines - The lines to set.
 */
export const initLines: SyncReducer<OfficeLines> = (
	state,
	_,
	{ office_slug, lines }
) => {
	const lens = R.lensPath(["offices", office_slug, "lines"]);
	if (Array.isArray(lines)) {
		return R.set(lens, R.indexBy(R.prop("slug"), lines), state);
	} else if (lines instanceof Object) {
		return R.set(lens, lines, state);
	} else return state;
};

type OfficeLine = {
	line: Line,
	office_slug: string,
};

/**
 * Adds one line to an office state
 * @param {Object} args
 * @param {Object} args.line
 * @param {string} args.officeSlug - The slug of the office.
 */
export const addLine: SyncReducer<OfficeLine> = (
	state,
	_,
	{ line, office_slug }
) => {
	const lens = R.lensPath(["offices", office_slug, "lines", line.slug]);
	return R.set(lens, line, state);
};

export const addLines = _produce((state, lines) => {
	const newLines = lines.map((l) => {
		const stateLines = state.lines
			.filter((sl) => sl.id === l.id)
			.reduce((_, c) => c, {});
		// lines from fetch() are memoized, so we give
		// priority to lines in the state, which are
		// updated.
		return { ...l, ...stateLines };
	});
	state.lines = state.lines
		.filter((sl) => !lines.some((l) => l.id === sl.id))
		.concat(newLines);
});

export const getLineX = async (state, dispatch, action) => {
	const { lineId } = action;
	//abort if line already exists
	if (state.lines.some((l) => l.id == lineId)) return state;
	await dispatch.apiCall({
		method: "get",
		path: `/lines/${lineId}`,
		onSuccess: dispatch.getLineSuccess,
		onFailure: dispatch.getLineFailure,
	});
};

//TODO: implement retry logic
export const getLineFailure = (state, _, action) => {
	return state;
};

// TODO: watch out for duplicated lines
export const getLineSuccess = (state, _, action) => ({
	...state,
	lines: state.lines.concat(action),
});

export const normalize = (state, dispatch, action) => ({});
