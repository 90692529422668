import { _produce } from "../util.js";

export const userDataUpdateRequested = _produce((state) => {
	state.user.isLoading = true;
	state.user.resolved = false;
});

export const userUpdated = _produce((state, action) => {
	//const { name, rut, dni } = action.data;
	state.user.isLoading = false;
	state.user.resolved = true;
	state.user = { ...state.user, ...action.data };
});

export const userUpdateFailed = _produce((state, action) => {
	state.user.isLoading = false;
	state.user.error = action;
});

export const resetUserUpdate = _produce((state, action) => {
	state.user.resolved = false;
});
