// @flow

import { curry, both, pathOr, complement } from "ramda";
import isCircularLib from "is-circular";
import isPlainObject from "is-plain-object";
import merge from "deepmerge";
import type { State } from "types";

const isCircular = (obj: any): boolean => {
	if (obj instanceof Object) return isCircularLib(obj);
	return false;
};

export const is_logged_in = (state: State): boolean =>
	// $FlowFixMe
	Boolean(pathOr(false, ["user", "token"], state));

export const mergeDeep = curry<?Object, ?Object>((a, b): Object => {
	return merge(a || {}, b || {}, {
		isMergeableObject: both(complement(isCircular), isPlainObject),
	});
});
