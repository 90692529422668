import * as R from "ramda";
import * as errors from "../network/errors.js";
import { _produce } from "./util.js";
import { selectLineById } from "state/lines/selectors";
import { getCurrentPosition, calculate_distance } from "lib/geolocation";
import { getWait } from "network/requests";
import {
	ticketsFromLocalStorage,
	setTicketLocalStorage,
	removeTemporaryTickets,
} from "io/localStorage";

// TODO: move passed action.reserve info to state.reserveQueue

export const pickReserveSaga = (state, dispatch, action) => {
	const { officeSlug, lineSlug, block, _lineId, meet, meta } = action;
	dispatch.reserveCreatedInit();
	// todo: remove this
	const lineId = pathOr(
		state,
		["offices", officeSlug, "lines", lineSlug, "id"],
		_lineId
	);
	// todo: remove this
	if (R.isNil(lineId) || !lineId) {
		dispatch.pickReserveSagaNotFoundException(
			new Error("Reserva no válida")
		);
		return;
	}
	// todo: remove this
	dispatch.getLineX({ lineId });
	// TODO: move to dispatch.setTimeout(100)
	// TODO: cancel timeout by appending this variable
	// to the reserve queue itself.
	setTimeout(() => {
		dispatch.reserveTimeout({
			lineId,
			error: new errors.TicketGetTimeoutException(),
		});
		dispatch.finishReserveSagaReducer({ lineId });
	}, 25000);

	dispatch.joinChannel({
		lineId,
		officeSlug,
		isReserve: true,
		block,
		meet: meet,
		meta,
	});
	dispatch.pickReserveSagaReducer({ lineId });
};

const pathOr = (obj, pathSegments, or) => {
	try {
		return pathSegments.reduce((p, c) => p[c], obj);
	} catch (e) {
		return or;
	}
};

// Saga of picking a ticket that consists of:
//   1. Joining a channel.
//   2. Subscribing to calls.
//   3. Picking a ticket.
//   TODO: this incorrectly assumes office's lines to be present.
export const pickTicketSaga = async (state, dispatch, action) => {
	setTimeout(() => {
		dispatch.finishSaga(new errors.TicketGetTimeoutException());
	}, 14000);
	const { officeSlug, lineSlug, _lineId } = action;
	const lineId = pathOr(
		state,
		["offices", officeSlug, "lines", lineSlug, "id"],
		_lineId
	);
	dispatch.pickTicketSagaReducer(action);
	dispatch.joinChannel({ lineId, officeSlug, isTicket: true });
};
export const pickTicketSagaWebOnly = async (state, dispatch, action) => {
	dispatch.pickTicketSagaReducer(action);
	dispatch.pickTicketRestFul(action);
};

export const pickTicketRestFul = async (state, dispatch, action) => {
	const { _lineId, officeId, officeSlug, meet, meta } = action;
	dispatch.genericWebModuleRequest({
		dinamicPath: `/offices/${officeId}/tickets/new`,
		method: "post",
		body: { line_id: _lineId, meet, meta },
		onSuccess: (ticket) => {
			dispatch.pickTicketRestFulMiddleware(ticket);
			// dispatch.reservationCreatedSuccess(created);
			// setTimeout(() => dispatch.updateReservation(created), 3000);
		},
		onFailure: (error) => dispatch.pickTicketFailure(error),
	});
};

export const pickTicketRestFulMiddleware = (state, dispatch, action) => {
	const { data: ticketToModify } = action;

	//se normaliza el ticket
	const ticket = {
		...ticketToModify,
		tuid: ticketToModify.id,
		lineSlug: ticketToModify.line.slug,
		line_slug: ticketToModify.line.slug,
		officeSlug: ticketToModify.office.slug,
		lineId: ticketToModify.line.id,
		officeId: ticketToModify.office.id,
	};

	const temporalUser = R.pathOr(false, ["user", "temporal"], state);
	const officeSlug = R.pathOr(null, ["office", "slug"], ticket);
	const lineSlug = R.pathOr(null, ["line", "slug"], ticket);
	const lineId = R.pathOr(null, ["line", "id"], ticket);
	const user_id = R.pathOr(null, ["user", "id"], ticket);
	const userToken = R.pathOr(false, ["user", "token"], state);
	const userId = state.user.id || state.user.user_id;
	const body = {
		slug: officeSlug,
		line: lineSlug,
		ticket: true,
		ticket_number: ticket.number,
		ticket_arrival: ticket.inserted_at,
	};
	dispatch.ticketPicked("Isgoin");
	if (user_id === userId) {
		dispatch.ticketCreated({ ...ticket, officeSlug });
		if (userToken) {
			getWait(userToken, body);
		}
	}

	const ticketForLocalStorage = {
		...ticket,
		line_name: state.linesv2.byId[lineId].name,
		officeSlug,
	};

	if (temporalUser) {
		dispatch.notifyTemporary();
		setTicketLocalStorage(ticketForLocalStorage);
	}
};

export const pickTicket = async (state, dispatch, action) => {
	const { officeSlug, lineId, channel } = action;
	if (
		state.ticketsv2.active
			.map((id) => state.ticketsv2.byId[id])
			.some((t) => t.line_id == lineId)
	) {
		dispatch.pickTicketFailure();
		return;
	}
	const userId = state.user.id || state.user.user_id;
	const cachedChannel = channel || state.channels.byId[officeSlug];

	cachedChannel.on("ticket:created", (t) => {
		const body = {
			slug: typeof office === "object" ? office.slug : office,
			line: t.lineSlug,
			ticket: true,
			ticket_number: t.number,
			ticket_arrival: t.inserted_at,
		};
		const user_id = R.pathOr(t.user_id, ["meta", "user", "user_id"], t);
		const userToken = R.pathOr(false, ["user", "token"], state);
		const temporalUser = R.pathOr(false, ["user", "temporal"], state);
		const lineName = R.pathOr(false, ["user", "token"], state);

		const ticketForLocalStorage = {
			...t,
			line_name: state.linesv2.byId[lineId].name,
			officeSlug,
		};

		if (temporalUser) {
			dispatch.notifyTemporary();
			setTicketLocalStorage(ticketForLocalStorage);
		}

		if (user_id === userId) {
			dispatch.ticketCreated({ ...t, officeSlug });
			if (userToken) {
				getWait(userToken, body);
			}
		}
	});

	const office = state.offices[officeSlug];

	cachedChannel
		.push(
			"ticket:new",
			{
				line_id: lineId,
				user_id: userId,
				meta: {
					rut: state.user.rut || null,
					/*
					 * position: {
						lat: userPosition ? userPosition.coords.latitude : null,
						lon: userPosition
							? userPosition.coords.longitude
							: null,
						distance:
							distance.distance === Infinity
								? null
								: distance.distance,
					},
					*/
				},
			},
			8000
		)
		// We add the ticket to the state before it is
		// created.
		.receive("ok", (t) => dispatch.ticketPicked(t))
		.receive("error", (err) => dispatch.ticketPickException(err))
		.receive("timeout", (err) =>
			dispatch.ticketPickedTimeoutException(err)
		);
};

/*
 {
   "payload":{
      "waiting":1,
      "wait":75599,
      "user_id":null,
      "urgent":false,
      "tz":"America/Santiago",
      "tuid":"T8441235711602702911698",
      "timestamp":"2020-10-15T16:15:10Z",
      "ticket_time":"2020-10-14T19:15:11.709Z",
      "ticket":{
         "weight":1,
         "user_id":261090,
         "tuid":"T8441235711602702911698",
         "timestamp":1602702911709,
         "reserve_id":null,
         "prefix":"PL",
         "number":2,
         "meta":{
            "user_id":261090,
            "rut":null,
            "original_line_id":8441
         },
         "line_id":8441,
         "generation":2357
      },
      "skipped_from_modulo":null,
      "skipped":false,
      "rut":"16.344.697-9",
      "ruid":null,
      "reserve_id":null,
      "reserve":false,
      "prefix":"PL",
      "number":2,
      "modulo_id":5292,
      "modulo":"Caja 3",
      "line_id":8441,
      "inserted_at":"2020-10-15T16:15:10Z",
      "generation":2357,
      "elapsed":null,
      "derivated_to_line":null,
      "derivated_from_line":null,
      "derivated":false,
      "cuid":"C52928441235721602778510009"
   }
}*
 *
 */
// Call if there is a ticket stored either in state.tickets or
// state.myReservations
// The ticket TUI is not fetched from the API endpoint so we
// have to use the line and user IDs.
// BUG: this assumes offices are loaded
// TODO: unify both tickets and reservations
export const callCreated = async (state, dispatch, action) => {
	const {
		line_id,
		ticket: calledTicket,
		ruid,
		tuid,
		waiting,
		wait,
		modulo,
		reserve_id,
		reserve,
	} = action;

	let payloadTicket = calledTicket;
	const { reserves, offices, ticketsv2, user } = state;

	const webServiceOfficeSlug = R.pathOr(
		null,
		["office", "slug"],
		calledTicket
	);
	const officeSlug = R.pathOr(webServiceOfficeSlug, ["officeSlug"], action);
	const office = state.offices[officeSlug];
	const isWebOnly = R.pathOr(false, ["options", "web_only"], office);

	if (isWebOnly) {
		payloadTicket = calledTicket ? {
			...calledTicket,
			user_id: action.user_id,
			tuid: calledTicket.id,
			line_id: action.line_id,
		} : {
			user_id: action.user_id,
			ruid: action.ruid,
			line_id: action.line_id,
		};
	}

	const myReservations = Object.values(state.myReservations.byId);
	dispatch.updateLine({ ...action, field: "wait" });
	dispatch.updateLine({ ...action, field: "waiting" });
	dispatch.setModuleOnAttention({ modulo, tuid });
	dispatch.updateLineField({
		field: "call",
		value: calledTicket,
		lineId: line_id,
	});
	dispatch.updateLineField({
		field: "waiting",
		value: waiting,
		lineId: line_id,
	});
	dispatch.updateLineField({
		field: "raw_tickets",
		value: waiting - 1,
		lineId: line_id,
		nested: "queue",
	});
	dispatch.updateLineField({
		field: "wait",
		value: wait,
		lineId: line_id,
	});

	if (user.temporal) {
		const { userTickets } = ticketsFromLocalStorage();
		const filter =
			userTickets.length &&
			userTickets.filter((ticket) => ticket.tuid !== tuid);
		removeTemporaryTickets("removeTickets");
		filter.length && filter.map((el) => setTicketLocalStorage(el));
	}

	ticketsv2.active
		.filter((tid) => tid === tuid)
		.forEach((t) =>
			dispatch.ticketCalled({
				ticket: { ...payloadTicket, ...t },
				call: action,
				office,
			})
		);

	// ticket of the logged-in user in the same line of the called ticket
	const fellowTicket = ticketsv2.active
		.map((id) => ticketsv2.byId[id])
		.filter(
			(t) =>
				t.line_id == line_id &&
				!calledTicket.reserve &&
				t.user_id !== null
		);
	fellowTicket.forEach((t) =>
		[5, 10].forEach(
			(n) =>
				t.number - calledTicket.number === n &&
				dispatch.callDrawingNear({
					by: n,
					ticket: t,
					office: offices[t.office ? t.office : t.officeSlug] || {},
					line: t.line_name,
				})
		)
	);

	myReservations
		.concat(reserves)
		.filter(
			(r) =>
				(r.id === reserve_id && reserve) ||
				(ruid && r.id === ruid)
		)
		.forEach((r) =>
			dispatch.ticketCalled({
				ticket: {
					...calledTicket,
					...r,
					prefix: "RV",
					number: String(r.id).substring(-3),
				},
				call: action,
				office: state.offices[action.officeSlug],
			})
		);
};

const getTicketError = (code) => {
	switch (code) {
		case "invalid-rut":
			return "El RUT no es válido";
		case "office-ticket-limit-reached":
			return "Se ha alcanzado el límite de tickets disponibles para su RUT en esta oficina";
		case "rut-line-limit-reached":
			return "Se ha alcanzado el límite de tickets disponibles para su RUT en esta fila";
		case "line-frozen":
			return "La fila en la que estás intentando sacar un ticket ha sido congelada";
		case "timeout": // not used
			return "Se ha superado el tiempo de espera para sacar un ticket";
		case "line-no-slug":
		case "line-no-prefix":
		case "line-no-exist":
			return "La fila en la que intentaste sacar un ticket ya no existe";
		default:
			return "Ha ocurrido un error inesperado. Intente nuevamente más tarde.";
	}
};

export const ticketErrored = (state, dispatch, action) => {
	const errorMessage = getTicketError(action.message);
	dispatch.finishSaga(new Error(errorMessage));
};

export const finishSaga = (state, dispatch, action) => {
	if (state.newTicket.isOngoing && action instanceof Error)
		dispatch.alert(action);
	dispatch.sagaFinished();
};

export const updateLine = _produce((state, action) => {
	const { line_id, field } = action;
	state.lines = state.lines.map((l) => {
		// Only updates loaded lines
		if (l.id !== line_id) {
			return l;
		}
		l[field] = action[field];
		return l;
	});

	const line = selectLineById(state.linesv2, line_id);

	if (!line.slug) return;

	state.linesv2.bySlug[line.slug][field] = action[field];
});

export const ticketPickedTimeoutException = (state, dispatch, action) => {
	dispatch.alert(new errors.TicketNewTimeoutException());
};
