// @flow

import * as R from "ramda";
import * as errors from "../../network/errors.js";
import { TimeoutError } from "lib/error";
import {
	fetchLines,
	fetchOffice,
	fetchReserves,
	fetchTickets,
	findLine,
	findLineFull,
	requestReserve,
	requestTicket,
} from "network/requests";
import { join } from "network/socket";
import { notify } from "./notification";
import type {
	Call,
	Channel,
	HourBlock,
	Office,
	Reserve,
	State,
	Ticket,
} from "types";
import type { Reducer } from "../types";

interface GetTicketArgs {
	office_slug: string;
	line_slug: string;
	callback: (Ticket) => void;
}

/**
 * Requests the location for a new ticket, then adds it to the state and start
 * listening for calls.
 * 1. get line id
 * 2. joins a channel if not previously joined = joinChannel
 * 3. add channel to state ==> channelJoined
 * 4. subscribe to ticket calls
 * 5. request a ticket
 * 6. add ticket to state
 * @async
 * @param {Object} args
 * @param {string} args.office_slug - The slug of the office.
 * @param {string} args.line_slug - The slug of the line.
 */
export const getTicket: Reducer<GetTicketArgs> = async (
	state,
	dispatch,
	{ office_slug, line_slug, callback }
) => {
	const timeout = setTimeout(() => {
		try {
			throw new errors.TicketGetTimeoutException();
		} catch (e) {
			dispatch.alert(e);
		}
	}, 14000);
	try {
		// Join office channel
		const channel: Channel = await join(office_slug);
		const line_id: ?number = R.pathOr(
			undefined,
			["offices", office_slug, "lines", line_slug, "id"],
			// $FlowFixMe
			state
		);
		if (R.isNil(line_id)) return;
		// Subscribe to call:created
		await listenForCalls(state, dispatch)({ line_id, user_id }, channel);
		// Get a ticket from that channel
		const user_id: number = state.user.user_id;
		const ticket: Ticket = await requestTicket(
			office_slug,
			line_id,
			user_id,
			channel
		);
		// Add ticket to state
		dispatch.newTicket({ ticket, office: office_slug });
		// Add channel to state
		dispatch.addListener(channel);
		clearTimeout(timeout);
		callback(ticket);
	} catch (e) {
		await dispatch.alert(e);
		callback(undefined);
		return;
	}
};

interface GetReserveArgs extends GetTicketArgs {
	block: HourBlock;
}

/**
 * Requests the location for a new reservation, then adds it to the state and start
 * listening for calls.
 * @async
 * @param {Object} args
 * @param {string} args.office_slug - The slug of the office.
 * @param {string} args.line_slug - The slug of the line.
 * @param {Object} args.block
 * @param {string} args.block.from - The start of the reservation time block.
 * @param {string{ args.block.to - The end of the reservation time block.
 */
export const getReserve: Reducer<GetReserveArgs> = async (
	state,
	dispatch,
	{ office_slug, line_slug, block }
) => {
	const { user, offices } = state;
	const line_id: ?number = R.pathOr(
		null,
		[office_slug, "lines", line_slug, "id"],
		// $FlowFixMe
		offices
	);
	if (R.isNil(line_id) || !line_id) return;
	try {
		await requestReserve(user.token, {
			reserve: {
				from: block.from.format("YYYY-MM-DDTHH:mm:ss"),
				to: block.to.format("YYYY-MM-DDTHH:mm:ss"),
				line_id,
			},
		});
		//await dispatch.waitingForReserveConfirmation(office_slug);
		const channel = await join(office_slug);
		const reserve = await listenForNewReserve(channel, line_id);
		await dispatch.newReserve({ reserve, office: office_slug });
		await dispatch.addListener(channel);
		return reserve;
	} catch (e) {
		if (e instanceof TimeoutError) {
			await dispatch.alert({
				message:
					"Se ha superado el tiempo máximo de espera. Por favor, inténtelo nuevamente más tarde.",
			});
			throw e;
		}
		switch (e.response.data.errors.from[0]) {
			case "Reserve already exists":
				await dispatch.alert({
					message:
						"Ya existe una reserva en este bloque horario. Por favor, selecciona otra fecha. ",
				});
				throw e;
			case "Reserve in the past":
				await dispatch.alert({
					message: "No se puede reservar una hora en el pasado",
				});
				throw e;
			default:
				throw e;
		}
	}
};

export const waitingForReserveConfirmation: Reducer<string> = async (
	state,
	_,
	office_slug
) => {
	const channel = await join(office_slug);
	const user_id = state.user.user_id;
	await new Promise((res, rej) => {
		channel.on("reserve:created", (reserve) => {
			if (reserve.user_id === user_id) {
				res();
			}
		});
		setTimeout(() => {
			rej(new TimeoutError('Timeout on "waitingForReserveConfirmation"'));
		}, 20000);
	});
};

const keyExists = (keys) => (key) => {
	if (keys.some((k) => k === key)) return true;
	keys.push(key);
	return false;
};

/**
 * Fetches all active tickets of an user and adds them to the state.
 */
/*
export const getTickets: Reducer<void> = async (state, dispatch) => {
	if (state.user.token) {
		const tickets = await fetchTickets(state.user.token).then(
			// TODO: this is duplicated. Call get lines only once
			initCallables
		);

		tickets.reduce((acc, t) => {
			if (acc.indexOf(t.office) === -1) {
				dispatch.joinChannel({
					officeSlug: t.office,
					lineId: t.line_id,
				});
			}
			acc.push(t.office);
			return acc;
		}, Object.keys(state.channels.byId));

		// adds tickets to state
		await dispatch.initTickets(tickets);
	}
};
*/

/**
 * Fetches all the active reservations for an user and adds them to the state.
 * @async
 */
export const getReserves: Reducer<void> = async (state, dispatch) => {
	try {
		const reserves = await fetchReserves(state.user.token).then(
			initCallables
		);
		reserves.forEach((r) =>
			dispatch.joinChannel({
				officeSlug: r.office,
				lineId: r.line_id,
			})
		);
		dispatch.addMyReservationsV1ToState(reserves);
		await dispatch.initReserves(reserves);
	} catch (e) {
		console.error(e);
	}
};

type Callable = Ticket | Reserve;
const initCallables = (callables: Callable[]) =>
	Promise.all(
		R.map(async (callable: Callable) => {
			const lineFull = await findLineFull(callable.line_id);
			const line = await findLine(callable.line_id);
			return {
				office: line.office_slug,
				line: line.line_slug,
				lineName: lineFull.name,
				...callable,
			};
		}, callables)
	);

const listenForCalls =
	(state: State, dispatch: any) =>
	async (ticket: Ticket, _channel: ?Channel) => {
		const { office_slug, line_slug } = await findLine(ticket.line_id);
		let office: ?Office = state.offices[office_slug];
		if (!office) {
			office = await fetchOffice(office_slug);
		}
		const lines = await fetchLines(office.id);
		const line = lines.filter((l) => l.slug === line_slug)[0];
		const channel: Channel = _channel || (await join(office_slug));
		//if (isListening("call:created", channel)) return channel;
		channel.on("time:estimate", dispatch.updateLine);
		channel.on("call:created", (call: ?Call) => {
			if (
				call &&
				call.ticket.user_id === ticket.user_id &&
				call.ticket.line_id == ticket.line_id
			) {
				//notify("Tienes una nueva llamada!");

				dispatch.callTicket({ ticket, office, line, call });
				//channel.off("call:created");
			} else if (call && call.ticket.number === ticket.number + 5) {
				dispatch.almostThere({
					call: { ticket },
					office,
					line: line.name,
				});
			}
		});
		return channel;
	};

const listenForCalls2 =
	(state: State, dispatch: any) =>
	async (ticket: Ticket, office_slug, line_slug, _channel) => {
		let office: ?Office = state.offices[office_slug];
		if (!office) {
			office = await fetchOffice(office_slug);
		}
		const lines = await fetchLines(office.id);
		const line = lines.filter((l) => l.slug === line_slug)[0];
		const channel: Channel = _channel || (await join(office_slug));
		//if (isListening("call:created", channel)) return channel;
		channel.on("time:estimate", dispatch.updateLine);
		channel.on("call:created", (call: ?Call) => {
			if (
				call &&
				call.ticket.user_id === ticket.user_id &&
				call.ticket.line_id == ticket.line_id
			) {
				//notify("Tienes una nueva llamada!"); // TODO change
				dispatch.callTicket({ ticket, office, line, call });
				//channel.off("call:created");
			} else if (call && call.ticket.number === ticket.number + 5) {
				dispatch.almostThere({
					call: { ticket },
					office,
					line: line.name,
				});
			}
		});
		return channel;
	};

const listenForNewReserve = (
	channel: Channel,
	line_id: number
): Promise<Reserve> =>
	new Promise((res, rej) => {
		channel.on("reserve:updated", (reserve: ?Reserve) => {
			if (reserve) {
				if (line_id === reserve.line_id && reserve.confirmed) {
					channel.off("reserve:updated");
					res(reserve);
				}
			}
		});
		setTimeout(() => rej(new TimeoutError("Timeout")), 20000);
	});

const isListening = (msg, channel) =>
	Boolean(R.find(R.propEq("event", msg), channel.bindings));
