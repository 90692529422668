import * as R from "ramda";
// import {
// 	apiOrigin as apiOriginProd,
// 	devDNS,
// 	labOriginUrl,
// 	stagingApiOrigin,
// 	webModuloServiceProd,
// 	webModuloServiceStaging,
// } from "config";
import { dataFromLocalStorage } from "io/localStorage";
import axios from "axios";

import envConfig from "../network/config";

const {
	apiOrigin: apiOriginEnv,
	turnOMaticApi,
	subDomain,
	lambdaUri,
} = envConfig;

export const dinamicUrl = apiOriginEnv;
const reservationsV3Uri = `${dinamicUrl}/services`;

export const dinamicUrlWebModule = turnOMaticApi;
export const lamdaURL = lambdaUri;

const subdomain = subDomain;

const apiOrigin = `https://${subdomain}zeroq.cl`;
const apiBaseURL = `https://${subdomain}zeroq.cl/api/v1`;

export const apiCall = async (state, dispatch, action) => {
	//TODO: pathOr
	// if (state.user.guest) return;
	const token = state.user.token;
	const method = action.method || "post";
	const { onSuccess, onFailure, path, body } = action;
	axios
		.create({
			baseURL: apiBaseURL,
			headers: { Authorization: token },
		})
		[method](path, body)
		.then(getData)
		.catch(onFailure)
		.then(onSuccess)
		.catch(dispatch.debug);
};

export const debug = (state, dispatch, action) => {
	if (process.env.NODE_ENV === "development") {
		dispatch.alert(action);
	}
};

export const stateCall = async (state, dispatch, action) => {
	//TODO: pathOr
	// if (state.user.guest) return;
	const token = state.user.token;
	const method = action.method || "post";
	const { onSuccess, onFailure, path, body, noCache } = action;
	const customHeaders = {
		Authorization: token,
		"Cache-Control": !noCache ? "" : "no-cache, no-store, must-revalidate",
		Pragma: "no-cache",
		Expires: "0",
	};
	const standarHeaders = {
		Authorization: token,
	};
	axios
		.create({
			baseURL: apiBaseURL,
			headers: noCache ? customHeaders : standarHeaders,
		})
		[method](path, body)
		.then((res) => res.data)
		.then(onSuccess)
		.catch(onFailure);
};

export const apiDelete = async (state, dispatch, action) => {
	const { onSuccess, onFailure, path, body } = action;
	const basepath = action.basepath || "/api/v1";
	const token = state.user.token;
	const axiosConfig = {
		data: body,
		headers: { Authorization: token },
	};

	axios
		.delete(apiOrigin + basepath + path, axiosConfig)
		.then(getData)
		.then(onSuccess)
		.catch(onFailure);
};

export const apiCall2 = async (state, dispatch, action) => {
	if (state.user.guest) return;
	const { onSuccess, onFailure, path, body } = action;
	const method = action.method || "post";
	const basepath = action.basepath || "/api/v1";
	const token = state.user.token;
	axios
		.create({
			baseURL: apiOrigin + basepath,
			headers: { Authorization: token },
		})
		[method](path, body)
		.then(getData)
		.then(onSuccess)
		.catch(onFailure);
};

const genericApi =
	(basepath, privateAccess) => async (state, dispatch, action) => {
		if (privateAccess) return;
		const { onSuccess, onFailure, path, body, method } = action;
		const token = state.user.token;
		axios
			.create({
				baseURL: basepath,
				headers: { Authorization: token },
			})
			[method || "post"](path, body)
			.then((res) => res.data)
			.catch(onFailure)
			// prevents previous failures from leaking to this "then"
			.then((res) => (typeof res === "undefined" ? null : onSuccess(res)))
			.catch(dispatch.debug);
	};
export const genericApiReservation = async (state, dispatch, action) => {
	const { onSuccess, onFailure, path, body, method, dinamicPath } = action;
	const baseURL = dinamicPath
		? `${dinamicUrl}/services/reservations/api/v2${dinamicPath}`
		: `${dinamicUrl}/services/reservations/api/v2`;
	const token = state.user.token;
	axios
		.create({
			baseURL: baseURL,
			headers: { Authorization: token },
			data: { body },
		})
		[method || "post"](path, body)
		.then((res) => {
			onSuccess(res.data);
		})
		.catch((err) => {
			if (dinamicPath) {
				return console.log("Reservation Update Failure");
			}
			onFailure(err.response.data);
		});
};

export const genericApiReservationV3 = async (state, dispatch, action) => {
	const { onSuccess, onFailure, path, body, method, dinamicPath } = action;
	const baseURL = dinamicPath
		? `${reservationsV3Uri}/reservations/api/v3${dinamicPath}`
		: `${reservationsV3Uri}/reservations/api/v3`;
	const token = state.user.token;
	axios
		.create({
			baseURL: baseURL,
			headers: { Authorization: token },
			data: { body },
		})
		[method || "post"](path, body)
		.then((res) => {
			onSuccess(res.data);
		})
		.catch((err) => {
			if (dinamicPath) {
				return console.log("Reservation Update Failure V3");
			}
			onFailure(err.response.data);
		});
};

export const formsApi = async (state, dispatch, action) => {
	const {
		onSuccess,
		onFailure,
		path,
		body,
		method,
		officeId,
		lineId,
		isTicket,
	} = action;
	const baseURL = `${dinamicUrl}/services/forms/config/office/${officeId}/line/${lineId}/current${
		isTicket ? "?ticket=true" : ""
	}`;
	const token = state.user.token;
	dispatch.setIsLoading(true);

	axios
		.create({
			baseURL: baseURL,
			headers: { Authorization: token },
			data: { body },
		})
		[method || "post"](path, body)
		.then((res) => {
			onSuccess(res.data);
			dispatch.setIsLoading(false);
		})
		.catch((err) => {
			onFailure(err);
			dispatch.setIsLoading(false);
		});
};

export const genericWebModuleRequest = async (state, dispatch, action) => {
	const { onSuccess, onFailure, path, body, method, dinamicPath } = action;
	const baseURL = `${dinamicUrlWebModule}${dinamicPath}`;
	const userFromLocalStorage = dataFromLocalStorage("user_data");

	const fallBackToken = R.pathOr(
		null,
		["data", "token"],
		userFromLocalStorage
	);

	const token = R.pathOr(fallBackToken, ["user", "token"], state);

	axios
		.create({
			baseURL: baseURL,
			headers: { Authorization: token },
			data: { body },
		})
		[method || "post"](path, body)
		.then((res) => {
			onSuccess(res);
		})
		.catch((err) => {
			onFailure(err);
		});
};

export const preferencesService = genericApi(
	`https://${subdomain}zeroq.cl/user/v2`,
	false
);

export const loginService = genericApi(`${dinamicUrl}/login`);

export const reservationService = genericApi(
	`${dinamicUrl}/services/reservations/api/v2`,
	false
);

export const reservationServiceV3 = genericApi(
	`${reservationsV3Uri}/reservations/api/v3`,
	false
);

export const getCategoriesByOrganizationService = async (
	state,
	dispatch,
	action
) => {
	const {
		onSuccess,
		onFailure,
		path,
		body,
		method,
		dinamicPath,
		organizationId,
	} = action;
	const baseURL = `${apiOrigin}/api/v3/organizations/${organizationId}/line_categories`;

	axios
		.create({
			baseURL,
			data: { body },
		})
		[method || "get"](path, body)
		.then((res) => {
			onSuccess(getData(res));
		})
		.catch((err) => {
			onFailure(err);
		});
};

export const getOfficesByCategoryIdService = async (
	state,
	dispatch,
	action
) => {
	const { onSuccess, onFailure, categoryId, path, body, method } = action;
	const baseURL = `${apiOrigin}/api/v3/line_categories/${categoryId}/geo`;

	axios
		.create({
			baseURL,
			data: { body },
		})
		[method || "get"](path, body)
		.then((res) => {
			onSuccess(res.data);
		})
		.catch((err) => {
			onFailure(err);
		});
};

const getData = R.path(["data", "data"]);
