// @flow
import * as R from "ramda";
import { _produce } from "./util.js";
import { normalize, schema } from "normalizr";
import type { Poll } from "types";
import type { SyncReducer } from "./types";

export const setPolls = _produce((state, polls) => {
	state.polls = polls;
});

// this is not a pop(), it is a shift()
export const popPoll: SyncReducer<> = (state) => {
	return R.over(R.lensProp("polls"), R.tail, state);
};

export const resetPolls = _produce((state, _) => {
	state.polls.byId = {};
});

export const getPollsX = async (state, dispatch) => {
	dispatch.getPollsReducer();
	dispatch.apiCall({
		path: "/user/polls",
		method: "get",
		onSuccess: dispatch.pollsGotten,
		onFailure: dispatch.pollsGetFailed,
	});
};

export const getPollsReducer = _produce((state, action) => {
	state.polls.isLoading = true;
});

const pollsSchema = [new schema.Entity("polls")];
export const pollsGotten = _produce((state, action) => {
	state.polls.byId = normalize(action, pollsSchema).entities.polls;
	state.polls.isLoading = false;
});

export const pollsGetFailed = _produce;

export const submitPollX = (state, dispatch, action) => {
	const { rating, description, ticket_id, office_id } = action;
	dispatch.submitPollReducer();
	dispatch.apiCall({
		path: "/user/polls",
		body: { poll: { rating, description, ticket_id, office_id } },
		onSuccess: dispatch.pollSubmitted,
		onFailure: dispatch.pollFailed,
	});
};

export const shiftPoll = _produce((state) => {
	state.polls.shift();
});
export const submitPollReducer = _produce((state) => {
	state.pollIsLoading = true;
});

export const pollSubmitted = _produce((state, action) => {
	state.polls.shift();
	state.notifications.shift();
	/*
	state.notifications.unshift({
		type: "success",
		message: "Evaluación enviada con éxito"
	});
	*/
	state.messages.push("Evaluación enviada con éxito");
	state.pollIsLoading = false;
});

export const pollFailed = _produce((state, action) => {
	const { message } = action;
	console.error(message);
	state.polls.shift();
	state.notifications.shift();
	//state.notifications.push({ type: "failure", message });
	state.pollIsLoading = false;
});
