import { pathOr } from "ramda";
import { _produce } from "../util.js";
import { normalize, schema } from "normalizr";
import Cookies from "js-cookie";

export const officeRequestReducer = _produce((state, action) => {
	const { officeSlug } = action;
	state.officesv2.isLoading = true;
	state.officesv2.loading.push(officeSlug);
});

export const officeRequestFailure = _produce((state, action) => {
	if (action?.response?.status === 429) {
		const value = Cookies.get("zq_number_of_try_webapp");
		const numberOfTry = value ? parseInt(value) : 0;
		if (numberOfTry === 0) {
			Cookies.set("zq_number_of_try_webapp", 1, { expires: 1 });
		} else if (numberOfTry === 1) {
			Cookies.set("zq_number_of_try_webapp", 2, { expires: 1 });
			state.toManyRequestsParams.numberOfTry = 1;
		} else if (numberOfTry >= 2) {
			Cookies.set("zq_number_of_try_webapp", 3, { expires: 1 });
			state.toManyRequestsParams.numberOfTry = 2;
		}

		state.toManyRequestsParams.toManyRequestError = true;
	}
	state.officesv2.isLoading = false;
	state.officesv2.error = action;
});

export const officeGotten = _produce((state, action) => {
	const { officeSlug, office } = action;
	const linesSchema = [new schema.Entity("lines")];
	const lines = pathOr(null, ["lines"], office);
	state.toManyRequestsParams.toManyRequestError = false; // aca limpiar cookies
	Cookies.remove("zq_number_of_try_webapp");
	state.officesv2.isLoading = false;
	state.officesv2.loading = state.officesv2.loading.filter(
		(of) => of !== officeSlug
	);
	state.officesv2.bySlug[officeSlug] = office;
	Object.values(lines)
		.filter((line) => !state.lines.some((l) => l.id === line.id))
		.forEach((line) =>
			state.lines.push({ ...line, officeSlug, officeId: office.id })
		);

	Object.values(lines).forEach(
		(line) =>
			(state.linesv2.bySlug[line.slug] = {
				...line,
				officeSlug,
				officeId: office.id,
			})
	);

	const { entities, result } = normalize(Object.values(lines), linesSchema);
	result.forEach(
		(lid) =>
			(state.linesv2.byId[lid] = { ...entities.lines[lid], officeSlug })
	);
	state.linesv2.loading = false;
});

export const hoursGotten = _produce((state, action) => {
	const { officeSlug, hours } = action;
	state.officeHours.isLoading = false;
	state.officeHours.byId[officeSlug] = hours;
});

export const setProcedureList = _produce((state, action) => {
	state.procedureByOffices.procedureList = action;
	state.procedureByOffices.isLoading = false;
});

export const setOfficeByProcedureLoading = _produce((state, action) => {
	state.procedureByOffices.isLoading = action;
	state.procedureByOffices.offices = [];
});

export const setOfficeByProcedureId = _produce((state, action) => {
	state.procedureByOffices.isLoading = false;
	state.procedureByOffices.offices = action;
});
