import { _produce } from "../util.js";

export const addChannelX = _produce((state, action) => {
	const { officeSlug, channel } = action;
	state.channels.byId[officeSlug] = channel;
});

export const channelLeft = _produce((state, action) => {
	const { officeSlug } = action;
	delete state.channels.byId[officeSlug];
});
