export const openDrawer = (state) => {
	return {
		...state,
		ui: {
			...state.ui,
			drawerOpen: true,
		},
	};
};

export const closeDrawer = (state) => {
	return {
		...state,
		ui: {
			...state.ui,
			drawerOpen: false,
		},
	};
};
