import { _produce } from "../util.js";
import { normalize, schema } from "normalizr";

export const getFavorites = async (state, dispatch, action) => {
	dispatch.getFavoritesReducer();
	dispatch.apiCall2({
		method: "get",
		basepath: "/user/v2",
		path: "/favorites",
		onSuccess: dispatch.favoritesGotten,
		onFailure: dispatch.favoritesFailed,
	});
};

export const createFavorite = async (state, dispatch, action) => {
	dispatch.getFavoritesReducer();
	dispatch.apiCall2({
		path: "/favorites/offices",
		basepath: "/user/v2",
		body: action,
		onSuccess: dispatch.favoriteOfficeCreated,
		onFailure: dispatch.favoriteOfficeCreationFailed,
	});
};

export const deleteFavoriteOffice = async (state, dispatch, action) => {
	dispatch.getFavoritesReducer();
	dispatch.apiDelete({
		path: "/favorites/offices",
		basepath: "/user/v2",
		body: action,
		onSuccess: dispatch.favoriteOfficeDeleted,
		onFailure: dispatch.favoriteOfficeDeletionFailed,
	});
};

export const favoriteOfficeDeleted = _produce((state, action) => {
	state.favorites.isLoading = false;
	state.favorites.offices = action.offices;
});

export const favoriteOfficeDeletionFailed = _produce((state, action) => {
	state.favorites.isLoading = false;
	state.favorites.errorMessage = action.message;
});

export const favoriteOfficeCreated = _produce((state, action) => {
	state.favorites.isLoading = false;
	state.favorites.offices = state.favorites.offices.concat(action.offices);
});

export const favoriteOfficeCreationFailed = _produce((state, action) => {
	state.favorites.isLoading = false;
	state.favorites.errorMessage = "error";
});

export const getFavoritesReducer = _produce((state, _) => {
	state.favorites.isLoading = true;
});

export const favoritesGotten = _produce((state, action) => {
	state.favorites.isLoading = false;
	state.favorites.offices = action[0].offices || [];
	state.favorites.lines = action[0].lines || [];
});

export const favoritesFailed = _produce((state, action) => {
	state.favorites.isLoading = false;
	state.favorites.errorMessage = action.message;
});
