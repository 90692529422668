import { _produce } from "./util.js";
import { normalize } from "normalizr";
import { ticketSchema, ticketSchemaWebOnly } from "./tickets/schema";
import * as Sentry from "@sentry/browser";

export const channelJoinException = (state, _, action) => ({
	...state,
	notifications: state.notifications.concat(action),
});

export const channelJoinTimeoutException = (state, _, action) => ({
	...state,
	notifications: state.notifications.concat(action),
});

// ReactN adapter
const adapt = (fn) => (state, _, action) => {
	if (process.env.NODE_ENV === "development") {
	}
	return fn(state, action);
};

export const pickTicketSagaReducer = (state) => ({
	...state,
	newTicket: {
		...state.newTicket,
		isOngoing: true,
	},
});

export const acquireChannelMutex = adapt((state, action) => ({
	...state,
	channels: {
		...state.channels,
		[action.officeSlug]: {
			locked: true,
		},
	},
}));

export const channelJoinedReducer = (state, _, action) => ({
	...state,
	channels: {
		...state.channels,
		[action.officeSlug]: {
			locked: true,
			joined: true,
		},
	},
});

/*
{
  "waiting": 2,
  "user_id": 131105,
  "updated_at": "2021-01-29T23:16:09.199Z",
  "tuid": "T941062251611962169198",
  "prefix": "P",
  "number": 25,
  "meta": {
    "user_id": 131105,
    "rut": null,
    "original_line_id": 9410
  },
  "location_name": "zeroQ QA Fisico",
  "line_id": 9410,
  "lineSlug": "zeroq-qa-fisico-pagos",
  "inserted_at": "2021-01-29T23:16:09.199Z",
  "id": 436,
  "generation": 62,
  "officeSlug": "zeroq-qa-fisico"
}
*/

export const notifyRegistry = _produce((state, action) => {
	state.notifications.push({
		type: "needRegistry",
		message: "ya sacaste un ticket para esta fila",
	});
});

export const notifyTemporary = _produce((state, action) => {
	state.notifications.push({
		type: "isTemporary",
		message: "ya sacaste un ticket para esta fila",
	});
});

export const ticketCreated = _produce((state, action) => {
	// v1 implementation for backward compatibility
	state.tickets = state.tickets.concat(action);

	// new implementation
	try {
		const { entities, result } = normalize(action, ticketSchema);
		state.ticketsv2.all.push(result);
		state.ticketsv2.active.push(result);
		// Object.assign(state.ticketsv2.byId, entities.ticket);
		state.ticketsv2.byId[result] = entities.ticket[result];
	} catch (err) {
		Sentry.captureException(err, {
			tags: {
				ticket: action,
			},
		});
	}
	if (action.temporaryUser) {
		return;
	}
	// new ticket
	state.newTicket = {
		...state.newTicket,
		ticket: action,
		isOngoing: false,
		didFinish: true,
	};
});

export const reservePicked = (state, _, action) => ({
	...state,
});

export const ticketPicked = (state, _, action) => state;
export const pickTicketFailure = _produce((state, action) => {
	state.newTicket.isOngoing = false;
	const { response } = action ?? {};
	const { data } = response ?? {};
	if (data?.message.includes("already has ticket")) {
		state.notifications.push({
			type: "alert",
			message:
				"Ya existe un ticket con este RUT, intente en otra fila, otra sucursal, o espere a ser llamado.",
		});
		return;
	}
	if (action && action.message) {
		state.notifications.push({
			type: "alert",
			message:
				"Ocurrio un error al generar el ticket, intente nuevamente.",
		});
		return;
	}

	state.notifications.push({
		type: "alert",
		message: "ya sacaste un ticket para esta fila",
	});
});

export const setUIConfig = _produce((state, action) => {
	const newState = { ...state.UIConfig, ...action };

	state.UIConfig = newState;
});
export const setCustomerParams = _produce((state, action) => {
	const newState = { ...state.UIConfig, ...action };
	state.customerQuery = newState;
});
// Removes the ticket from local state, notifies
// BUG: assumes the office has lines (this bug can be solved by
// subscribing to lines directly)
// TODO: getLine on (1) ticketLoaded (2)ticketPicked
export const ticketCalled = _produce((state, action) => {
	// should not notify if user is guest
	if (state.user.guest) throw new Error("guest is listening to ticket calls");
	// remove called ticket from state
	state.tickets = state.tickets.filter((t) => t.tuid !== action.ticket.tuid);
	state.called.ids = state.called.ids.concat(action.ticket.id);
	// move called ticket to ticket history array
	state.ticketsv2.called.push(action.ticket.tuid);
	state.ticketsv2.active = state.ticketsv2.active.filter(
		(t) => t !== action.ticket.tuid
	);
	// mobile uses historic.ids, while desktop uses historic.byPage
	state.ticketsv2.historic.ids.push(action.ticket.tuid);
	if (state.ticketsv2.historic.byPage["1"]) {
		state.ticketsv2.historic.byPage["1"].push(action.ticket.tuid);
	} else {
		state.ticketsv2.historic.byPage["1"] = [action.ticket.tuid];
	}
	// notify

	state.notifications.push({
		...action,
		ticket: {
			inserted_at: action?.call?.ticket_time,
			...action?.call?.ticket,
		},
		type: "call",
		// TODO: BUG: the component should retrieve data directly.
		// This is not subscribed to latest state updates.
		line: state.lines
			.filter((l) => l.id == action.call.line_id)
			.reduce((defaultO, l) => l.name, "Sin información"),
	});
});

// TODO: the component should retrieve data directly.
export const callDrawingNear = _produce((state, action) => {
	const { office, line, ticket, by } = action;

	state.notifications.push({
		type: "almost_there",
		office,
		line,
		ticket,
		by,
	});
});
export const setModuleOnAttention = _produce((state, action) => {
	const { modulo, tuid } = action;

	state.moduleOnAttention.push({
		modulo,
		tuid,
	});
});

export const sagaFinished = (state, _, action) => ({
	...state,
	newTicket: {
		...state.newTicket,
		isOngoing: false,
	},
});

export const sagaRestarted = (state, _, action) => ({
	...state,
	newTicket: {
		isOngoing: false,
		didFinish: false,
	},
});

export const setIsLoading = _produce((state, action) => {
	state.isLoading = action;
});
