// @flow

import * as R from "ramda";
import { getCurrentPosition } from "lib/geolocation";
import { updateUser } from "network/login";
import type { Reducer } from "../types";
import type { User } from "types";

/**
 * Updates the user's rut to the server and the state.
 * @async
 * @param {string} rut
 */
export const registerRut: Reducer<string> = async (state, dispatch, rut) => {
	if (state.guest) return;
	const { user } = state;
	const new_user = R.assoc("rut", rut, user);
	await updateUser(new_user, new_user.token);
	dispatch.setUser(new_user);
};

export const registerDni = async (state, dispatch, dni) => {
	const { user } = state;
	const new_user = R.assoc("dni", dni, user);
	await updateUser(new_user, new_user.token);
	dispatch.setUser(new_user);
};

/**
 * Updates the user's info to the server and the state.
 */
export const updateUserInfo: Reducer<User> = async (state, dispatch, user) => {
	if (state.guest) throw Error("User is guest");
	const oldUser = state.user;
	const new_user = R.mergeRight(state.user, user);
	if (new_user.rut === "-") delete new_user.rut;

	dispatch.setUser({ ...oldUser, isLoading: true });
	await updateUser(new_user, new_user.token);
	dispatch.setUser({ ...oldUser, isLoading: false });
};

export const getUserPosition: Reducer<> = async (_state, dispatch) => {
	const position = await getCurrentPosition();
	await dispatch.updateUserPosition(position);
};
