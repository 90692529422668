import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { beamsInstanceId, tokenProviderBeams } from "config";

export const pushNotificacionSubscriptionProfile = (checked, userId) => {
	window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
		const tokenProvider = new PusherPushNotifications.TokenProvider({
			url: tokenProviderBeams,
			queryParams: {
				user_id: userId, // URL query params your auth endpoint needs
			},
		});
		try {
			const beamsClient = new PusherPushNotifications.Client({
				instanceId: beamsInstanceId,
				serviceWorkerRegistration: serviceWorkerRegistration,
			});
			if (checked === true) {
				beamsClient
					.start()
					.then(() => {
						beamsClient
							.setUserId(userId, tokenProvider)
							.then(() => {})
							.catch((e) => {
								return console.log(
									"ha ocurrido un error de beams",
									e.message
								);
							});
					})
					.catch((e) =>
						console.log("ha ocurrido un eror con Beams", e.message)
					);
			}
			if (checked === false) {
				beamsClient.stop();
			}
		} catch (error) {
			console.log("Error", error);
		}
	});
};

export const pushNotificacionSubscription = (userId) => {
	window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
		const tokenProvider = new PusherPushNotifications.TokenProvider({
			url: tokenProviderBeams,
			queryParams: {
				user_id: userId, // URL query params your auth endpoint needs
			},
		});
		try {
			const beamsClient = new PusherPushNotifications.Client({
				instanceId: beamsInstanceId,
				serviceWorkerRegistration: serviceWorkerRegistration,
			});
			beamsClient
				.start()
				.then(() => {
					beamsClient
						.setUserId(userId, tokenProvider)
						.then(() => {
							console.log("Registered user successfully");
						})
						.catch((e) => {
							return console.log(
								"ha ocurrido un error de beams",
								e.message
							);
						});
					console.log("usuario registrado", userId);
				})
				.catch((e) =>
					console.log("ha ocurrido un eror con Beams", e.message)
				);
		} catch (error) {
			console.log("Error", error);
		}

		// beamsClient
		// 	.start()
		// 	.then((beamsClient) => beamsClient.getDeviceId())
		// 	.then((deviceId) =>
		// 		console.log(
		// 			"Successfully registered with Beams. Device ID:",
		// 			deviceId
		// 		)
		// 	)
		// 	.then(() => beamsClient.addDeviceInterest("test"))
		// 	.then(() => beamsClient.getDeviceInterests())
		// 	.then((interests) => console.log("Current interests:", interests))
		// 	.catch(console.error);
	});
};

export const unsubscribePushNotifications = () => {
	window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
		console.log(serviceWorkerRegistration);
		try {
			const beamsClient = new PusherPushNotifications.Client({
				instanceId: beamsInstanceId,
				serviceWorkerRegistration: serviceWorkerRegistration,
			});

			// beamsClient
			// 	.start()
			// 	.then(() => beamsClient.removeDeviceInterest("test"))
			// 	.then(() => console.log("interes Eliminado"))
			// 	.catch((e) => console.error("Could not remove device interest", e));

			return beamsClient
				.stop()
				.then(() => console.log("beamsClient stoped"))
				.catch(() => console.log("error al detener clientBeams"));
		} catch (error) {
			console.log("Error", error);
		}
	});
};
