// @flow
import { setDataForCache, dataFromLocalStorage } from "io/localStorage";
import { refreshCachedData } from "lib/refreshCachedData";

import * as R from "ramda";
import { Channel } from "phoenix-socket";
import {
	fetchCategories,
	fetchHourBlocks,
	fetchOffices,
} from "network/requests";
import { join, leave } from "network/socket";
import type { Office } from "types";
import type { Reducer } from "../types";

/**
 * Fetches the offices from the server and adds them to the global state.
 * If given a slug, it will fetch for that Office slug.
 * @async
 * @param {string=} slug - The slug of the office
 */
export const getOffices: Reducer<?string> = async (state, dispatch, slug) => {
	const { data } = dataFromLocalStorage("offices");
	if (data) {
		const needRefresh = refreshCachedData(data);
		if (needRefresh) {
			const offices = await fetchOffices();
			await dispatch.setOffices(offices);
			await setDataForCache({ data: offices, key: "offices" });
			return;
		}
		await dispatch.setOffices(data.data);
	} else {
		if (R.isEmpty(state.offices)) {
			const offices = await fetchOffices();
			await dispatch.setOffices(offices);
			await setDataForCache({ data: offices, key: "offices" });
		}
	}

	await dispatch.getCategories();
	if (slug) await dispatch.getLines(slug);
};

// FIXME: do real stuff with this function
// istanbul ignore next
// eslint ignore next
export const getOfficeState: Reducer<string> = async (state, _, slug) => {
	const channel: Channel = await join(slug);
	await new Promise((res) => {
		channel.on("ticket:created", res);
		channel.on("call:created", res);
	});
};

/**
 * Fetches the categories from the server and adds them to the global state.
 * @async
 */
export const getCategories: Reducer<void> = async (_, dispatch) => {
	const categories = await fetchCategories();
	await dispatch.setCategories(categories);
};

/**
 * Fetches the hours from the server and adds them to the global state.
 * @async
 */
export const getHourBlocks: Reducer<void> = async ({ offices }, dispatch) => {
	const offices_id = R.pipe(
		R.values,
		R.map(R.pick<any, any>(["id", "slug"]))
	)(offices);
	const promises = R.map(async ({ id, slug }) => {
		const blocks = await fetchHourBlocks(id);
		dispatch.setOfficeBlocks({ office: slug, blocks });
	}, offices_id);
	await Promise.all(promises);
};

/**
 * Fetches the hour block for a single office and adds it to the global state.
 * @async
 * @param {string} office
 */
export const getHourBlock: Reducer<string> = async (
	{ offices },
	dispatch,
	office
) => {
	const office_id = R.path([office.slug, "id"], offices);
	if (!office_id) return;
	const blocks = await fetchHourBlocks(office_id);
	if (
		offices[office] &&
		offices[office].hours &&
		Array.isArray(office.hours) &&
		office.hours.length !== 0
	)
		return;
	dispatch.setOfficeBlocks({ office, blocks });
};

export const getHourBlock2: Reducer<string> = async (
	{ offices },
	dispatch,
	office
) => {
	const office_id = R.path([office, "id"], offices);
	if (!office_id) return;
	const blocks = await fetchHourBlocks(office_id);
	if (
		offices[office] &&
		offices[office].hours &&
		Array.isArray(offices[office].hours) &&
		offices[office].hours.length !== 0
	)
		return;
	dispatch.setOfficeBlocks({ office: offices[office], blocks });
};

export const leaveOfficeChannel: Reducer<string> = async (
	_s,
	_d,
	office_slug
) => {
	await leave(office_slug);
};
