import { Container } from "./styles";
import React, { useState, useEffect } from "react";

const waitMessages = [
	"Estamos planificando tu asesoria...",
	"Estamos buscando fechas disponibles ...",
	"Estamos buscando horarios disponibles ...",
];

export const Loader = () => {
	const [messageIndex, setMessageIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setMessageIndex(
				(prevIndex) => (prevIndex + 1) % waitMessages.length
			);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<Container>
			<div className="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<h3>Espera un momento...</h3>
			<p>{waitMessages[messageIndex]}</p>
		</Container>
	);
};
