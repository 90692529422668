import { customersQuery } from "lib/data/querys";
import { setGlobal } from "reactn";
import { useHistory } from "react-router";
import { useQuery } from "hooks/useQuery";
import React, { useEffect } from "react";
const UIWrapper = ({ children }) => {
	const query = useQuery();
	const history = useHistory();

	useEffect(() => {
		const newParams = {};

		customersQuery.map((key) => {
			if (query.get(key)) {
				newParams[key] = query.get(key);
			}
		});
		const newParamsKeys = Object.keys(newParams);
		const wantToLoadOffices = newParamsKeys.length === 0;

		setGlobal({
			customerParams: newParams,
			loadOffices: wantToLoadOffices,
		});
	}, []);

	useEffect(() => {
		const newParams = {};
		if (query.get("itemsToHide")) {
			const items = query.get("itemsToHide").split(",");
			items.forEach((item) => {
				newParams[
					"hide" + item.charAt(0).toUpperCase() + item.slice(1)
				] = true;

				if (item === "backArrow") {
					newParams.backArrowpath = history.location.pathname;
				}
			});
			setGlobal({ UIConfig: { ...newParams, itemsToHide: true } });
		}
	}, []);

	return <>{children}</>;
};

export default UIWrapper;
