export const setCategoryContext = (state, dispatch, action) => {
	dispatch.setCategoryContext(state, action);
};

export const setDistrictFilter = (state, dispatch, action) => {
	dispatch.setDistrictFilter(state, action);
};

export const applySearchValue = (state, dispatch, action) => {
	dispatch.applySearchValue(state, action);
};

export const resultsQuantity = (state, dispatch, action) => {
	dispatch.resultsQuantity(state, action);
};
