import { _produce } from "./util.js";

export const notify = _produce((state, action) => {
	state.notifications.push({
		type: "alert",
		message: action,
	});
});

export const enqueueNotification = (state, dispatch, action) => {
	dispatch.closeNotification();
	setTimeout(() => dispatch.createNotification(action), 120e3);
};

export const createNotification = _produce((state, action) => {
	state.notifications.push(action);
});

export const addMessage = _produce((state, action) => {
	state.messages.push(action);
});

export const removeMessages = _produce((state) => {
	state.messages = [];
});
