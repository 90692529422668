export function TicketRequestTimeoutException() {
	this.name = "TicketRequestTimeoutException";
	this.stack = new Error().stack;
	this.message =
		"Se ha superado el tiempo de espera. Intente más tarde nuevamente.";
}

TicketRequestTimeoutException.prototype = new Error();

export function TicketRequestException() {
	this.name = "TicketRequestException";
	this.stack = new Error().stack;
	this.message = "Ha ocurrido un error al generar el ticket";
}

TicketRequestException.prototype = new Error();

export function TicketNewTimeoutException() {
	this.name = "TicketNewTimeoutException";
	this.stack = new Error().stack;
	this.message =
		"Se ha superado el tiempo de espera del servidor. Intente más tarde nuevamente.";
}

TicketNewTimeoutException.prototype = new Error();

export function ChannelJoinException() {
	this.name = "ChannelJoinException";
	this.stack = new Error().stack;
	this.message =
		"No se ha podido conectar con la sucursal. Intente más tarde nuevamente.";
}

ChannelJoinException.prototype = new Error();

export function ChannelJoinTimeoutException() {
	this.name = "ChannelJoinTimeoutException";
	this.stack = new Error().stack;
	this.message =
		"Se ha superado el tiempo de espera de la sucursal. Intente más tarde nuevamente.";
}

ChannelJoinTimeoutException.prototype = new Error();

export function TicketGetTimeoutException() {
	this.name = "TicketGetTimeoutException";
	this.stack = new Error().stack;
	this.message =
		"Se ha superado el tiempo de espera. Inténtelo de nuevo más tarde.";
}

TicketGetTimeoutException.prototype = new Error();

export function LinesFetchException() {
	this.name = "LinesFetchException";
	this.stack = new Error().stack;
	this.message =
		"No se han podido cargar las filas. Por favor, recargue la página.";
}

LinesFetchException.prototype = new Error();
