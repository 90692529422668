export const toManyRequestsTextDefinitions = {
	toManyRequestsText: {
		en: "Too many requests, please try again later",
		es: "En este momento estamos recibiendo demasiadas solicitudes. Por favor, inténtelo de nuevo más tarde.",
	},
};

export const MODAL_TYPES = {
	CONFIRMATION: "confirmation",
	ERROR: "error",
	TO_MANY_REQUESTS_ERROR: "to_many_requests_error",
};
