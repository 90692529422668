import { isTwoHoursDiff } from "lib/date";
import {
	removeTemporaryTickets,
	ticketsFromLocalStorageToCheck,
} from "io/localStorage";
import React, { useEffect } from "react";
import { pathOr } from "ramda";

export const useCheckTemporaryTickets = () => {
	const currentTickets = ticketsFromLocalStorageToCheck();
	const ticketsInLocalStorage = pathOr(null, ["tickets"], currentTickets);

	useEffect(() => {
		const timeStamp = currentTickets?.timeStamp || false;
		if (ticketsInLocalStorage && timeStamp) {
			const needToDeleteTickets = isTwoHoursDiff(timeStamp);
			if (needToDeleteTickets) {
				removeTemporaryTickets("removeTicket");
				window.location.reload();
			}
			return;
		}
		if (ticketsInLocalStorage && !timeStamp) {
			removeTemporaryTickets("removeTicket");
			window.location.reload();
		}
	}, []);

	return {};
};
