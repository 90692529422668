import * as R from "ramda";
import * as errors from "./errors.js";
import { Socket } from "phoenix-socket";
import { channelJoinedReducer } from "../state/reducers.js";
import { getDispatch, getGlobal } from "reactn";
import config from "./config.js";

const { socket } = config;

const URL = socket;

export const initSocket = (_socket) => {
	const s = _socket || Socket;
	const socket = new s(URL, { params: {} });
	socket.connect();
	return socket;
};

export const join = (office_slug) =>
	new Promise((res, rej) => {
		const state = getGlobal();
		const { addChannel, mergeOfficeState } = getDispatch();

		const { offices, user, socket } = state;

		let channel = R.pathOr(null, [office_slug, "channel"], offices);
		if (channel) return res(channel);

		channel = socket.channel(`web:${office_slug}`, { token: user.token });
		channel
			.join(12000)
			.receive("ok", (office_state) => {
				addChannel({ office: office_slug, channel });
				mergeOfficeState(office_state);
				res(channel);
			})
			.receive("error", () => rej(new errors.ChannelJoinException()))
			.receive("timeout", () =>
				rej(new errors.ChannelJoinTimeoutException())
			);
	});

export const joinLobby = () =>
	new Promise((res, rej) => {
		const { socket } = getGlobal();
		const channel = socket.channel("web:lobby", { token: null });
		channel
			.join()
			.receive("ok", (response) => {
				res({ channel, response });
			})
			.receive("error", (message) => {
				rej(Error(message));
			})
			.receive("timeout", () => {
				rej(Error("timeout"));
			});
	});

export const leave = (office_slug) =>
	new Promise((res, rej) => {
		const { offices } = getGlobal();
		const { removeChannel } = getDispatch();
		const channel = R.propOr(undefined, office_slug, offices);
		if (channel && channel.leave) {
			channel
				.leave()
				.receive("ok", () => {
					removeChannel(office_slug);
					res();
				})
				.receive("error", (err) => {
					rej(err);
				});
		} else {
			res();
		}
	});
