import { calculate_distance, getCurrentPosition } from "lib/geolocation";
import { dataFromLocalStorage, setDataForCache } from "io/localStorage";
import { refreshCachedOfficeAndLines } from "lib/refreshCachedData";
const organizationsFile = require("lib/data/organizations.json");

export const getOfficeMiddleware = (officeSlug) => {
	const officeSlugCached = localStorage.getItem("officeSlugWeb");

	const { data: officeCached } = dataFromLocalStorage("office");

	if (officeSlugCached !== officeSlug) return false;

	if (officeCached) {
		const needRefresh = refreshCachedOfficeAndLines(officeCached);
		if (!needRefresh) {
			const office = officeCached.data;
			return office;
		}
	}

	const { data: officesCached } = dataFromLocalStorage("offices");
	const needRefresh = refreshCachedOfficeAndLines(officesCached);

	if (officesCached && !needRefresh) {
		const offices = officesCached.data;
		const office = offices[officeSlug];
		const { data: linesCached } = dataFromLocalStorage(`lines`);
		return { ...office, lines: linesCached && linesCached.data };
	}

	return false;
};

export const requestOffice = async (state, dispatch, action) => {
	const { officeSlug } = action;

	if (!officeSlug) return;

	if (state.officesv2.bySlug[officeSlug]) return;

	if (state.officesv2.loading.indexOf(officeSlug) !== -1) return;

	// const officeCached = getOfficeMiddleware(officeSlug);
	// if (officeCached && officeCached.lines) {
	// 	dispatch.decorateOfficeDistance({
	// 		officeSlug,
	// 		office: officeCached,
	// 	});
	// 	return;
	// }

	dispatch.officeRequestReducer({ officeSlug });

	await dispatch.stateCall({
		method: "get",
		path: `/state/${officeSlug}`,
		onSuccess: (office) => {
			setDataForCache({ data: office, key: "office" });
			localStorage.setItem("officeSlugWeb", officeSlug);
			dispatch.decorateOfficeDistance({ officeSlug, office });
		},
		onFailure: dispatch.officeRequestFailure,
		noCache: true,
	});
};

export const decorateOfficeDistance = (state, dispatch, action) => {
	const { officeSlug, office } = action;
	// const userPosition = {
	// 	coords: {
	// 		latitude: -33.45020047,
	// 		longitude: -70.6542286,
	// 	},
	// };
	getCurrentPosition()
		.then((pos) => {
			const distance = calculate_distance(pos, office);
			dispatch.officeGotten({
				office: { ...office, distance },
				officeSlug,
			});
			return;
		})
		.catch((err) =>
			dispatch.officeGotten({ office: { ...office }, officeSlug })
		);

	// const distance = calculate_distance(userPosition, office);

	dispatch.officeGotten({ office: { ...office }, officeSlug });
};

export const getOfficeHours = async (state, dispatch, action) => {
	const { officeId, officeSlug } = action;
	await dispatch.apiCall({
		method: "get",
		path: `/offices/${officeId}/hours`,
		onSuccess: (hours) =>
			dispatch.hoursGotten({ hours, officeId, officeSlug }),
		onFailure: dispatch.hourRequestFailed,
	});
};

export const getCategoriesByOrganization = async (state, dispatch, action) => {
	dispatch.setOfficeByProcedureLoading(true);
	const { categoryId } = action;
	const organizationByCategories = organizationsFile.categories;
	const { organizationId } =
		organizationByCategories.find(({ id }) => id === categoryId) || {};

	await dispatch.getCategoriesByOrganizationService({
		method: "get",
		onSuccess: dispatch.setProcedureList,
		onFailure: console.log,
		organizationId,
	});
};

export const getOfficesByCategoryId = async (state, dispatch, action) => {
	const { categoryId } = action;
	dispatch.setOfficeByProcedureLoading(true);
	await dispatch.getOfficesByCategoryIdService({
		method: "get",
		onSuccess: dispatch.setOfficeByProcedureId,
		onFailure: console.log,
		categoryId,
	});
};
