import moment from "moment-timezone";

export const refreshCachedData = (cachedData) => {
	if (!cachedData) return;
	const { inserted_at } = cachedData;
	const max = 30;
	const min = 10;
	const randomMinutes = Math.random() * (max - min) + min;
	const minutes = parseInt(randomMinutes.toFixed());
	const now = moment();
	const dataDate = moment(inserted_at);
	const diff = now.diff(dataDate, "minutes");

	if (diff > minutes) return true;

	return false;
};

export const refreshCachedOfficeAndLines = (cachedData) => {
	if (!cachedData) return;
	const { inserted_at } = cachedData;
	const max = 5;
	const min = 1;
	const randomMinutes = Math.random() * (max - min) + min;
	const minutes = parseInt(randomMinutes.toFixed());
	const now = moment();
	const dataDate = moment(inserted_at);
	const diff = now.diff(dataDate, "minutes");

	if (diff > minutes) return true;

	return false;
};
