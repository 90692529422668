import { DateTime } from "luxon";
import { addCode } from "lib/tickets";
import { mapReservationsToV2 } from "./reducers";
import io from "socket.io-client";

const subdomain =
	window &&
	window.location.hostname.match(/(([^.]+)\.)?zeroq\.cl/) &&
	window.location.hostname.match(/(([^.]+)\.)?zeroq\.cl/)[2] === "staging"
		? "staging."
		: window && window.location.hostname === "lab.zeroq.cl"
		? "lab."
		: window && window.location.hostname === "dev.zeroq.cl"
		? "dev."
		: "";

const endpointReservas = `https://${subdomain}zeroq.cl`;

export const requestUnavailableDays = (state, dispatch, action) => {
	const { officeSlug, lineSlug } = action;
	//dispatch.unavailableDaysRequested(action);
	dispatch.reservationService({
		method: "get",
		path: `/offices/${officeSlug}/calendar/unavailable`,
		onSuccess: (res) =>
			dispatch.unavailableDaysFetched({ ...action, days: res.dates }),
		onFailure: dispatch.unavailableDaysFetchFailed,
	});
};

/*
 * reservation shape : { lineSlug, officeSlug, token, from, to }
 */
export const enqueueReservation = async (state, dispatch, reservation) => {
	dispatch.reservationEnqueued(reservation);
	dispatch.enqueueReservationRestFul(reservation);
	//dispatch.setReservationTimeout(reservation.lineSlug);
};
export const enqueueReservationV3 = async (state, dispatch, reservation) => {
	if (reservation && reservation.oldIdReservation) {
		dispatch.setOldIdReservation(null);
	}
	dispatch.reservationEnqueued(reservation);
	dispatch.enqueueReservationRestFulV3(reservation);
	//dispatch.setReservationTimeout(reservation.lineSlug);
};
// export const onFailureR = (state, dispatch, data) => {
// 	dispatch.reservationCreatedFailure((data && data) || "Fallo");
// 	dispatch.clearAllReservationTimeouts();
// 	dispatch.finishReservationSaga();
// };
export const enqueueReservationRestFul = async (
	state,
	dispatch,
	reservation
) => {
	dispatch.genericApiReservation({
		method: "post",
		body: reservation,
		onSuccess: (created) => {
			dispatch.setLocalTemporaryReservations(created);
			dispatch.reservationCreatedSuccess(created);
			setTimeout(() => dispatch.updateReservation(created), 3000);
		},
		onFailure: dispatch.reservationCreatedFailure,
	});
};

export const enqueueReservationRestFulV3 = async (
	state,
	dispatch,
	reservation
) => {
	dispatch.genericApiReservationV3({
		method: "post",
		body: reservation,
		onSuccess: (created) => {
			dispatch.setLocalTemporaryReservations(created);
			dispatch.reservationCreatedSuccess(created);
			setTimeout(() => dispatch.updateReservationV3(created), 3000);
		},
		onFailure: dispatch.reservationCreatedFailure,
	});
};

export const updateReservation = (state, dispatch, reservation) => {
	dispatch.genericApiReservation({
		method: "get",
		dinamicPath: `/${reservation._id}`,
		onSuccess: (reservation) => {
			dispatch.decorateReservation(reservation);
		},
		onFailure: dispatch.reservationCreatedFailure,
	});
};

export const updateReservationV3 = (state, dispatch, reservation) => {
	dispatch.genericApiReservationV3({
		method: "get",
		dinamicPath: `/${reservation._id}`,
		onSuccess: (reservation) => {
			dispatch.decorateReservation(reservation);
		},
		onFailure: dispatch.reservationCreatedFailure,
	});
};

export const getReservationById = (state, dispatch, id) => {
	dispatch.genericApiReservation({
		method: "get",
		dinamicPath: `/${id}`,
		onSuccess: (reservation) => {
			dispatch.handleReservationById(addCode({ ...reservation, id }));
		},
	});
};
export const getReservationByIdV3 = (state, dispatch, id) => {
	dispatch.genericApiReservationV3({
		method: "get",
		dinamicPath: `/${id}`,
		onSuccess: (reservation) => {
			dispatch.handleReservationById(addCode({ ...reservation, id }));
		},
	});
};

//deprecated
/*
export const setReservationTimeout = (state, dispatch, lineId) => {
	const timeout = setTimeout(() => {
		dispatch.reservationTimeoutFinished(lineId);
	}, 15e3);
	dispatch.reservationTimeoutSet({ lineId, timeout });
};
*/

//deprecated now reservations V2 are restFul
// export const joinReservationChannel = (state, dispatch) => {
// 	const socket = io(endpointReservas, {
// 		path: "/services/reservations/api/v2/websocket",
// 	});
// 	/*
// 	if (state.socketio.state === "connected") {
// 		dispatch.createReservation(state.socketio);
// 	}
// 	*/

// 	// response on create reservation
// 	socket.on("reservation.created", (created) => {
// 		if (created.code < 300) {
// 			dispatch.setLocalTemporaryReservations(created.data);
// 			dispatch.reservationCreatedSuccess(created.data);
// 			return;
// 		}
// 		dispatch.reservationCreatedFailure(created.message);
// 		dispatch.clearAllReservationTimeouts();
// 		dispatch.finishReservationSaga();
// 		//created.message === "user have reservation in this office"){
// 	});

// 	//confirm reservation created
// 	socket.on("reservation.confirmed", (confirmed) => {
// 		dispatch.decorateReservation(confirmed);
// 	});

// 	socket.on("reservation.deleted", (deleted) => {});

// 	socket.on("disconnect", function () {
// 		dispatch.changeSocketState("disconnected");
// 		dispatch.joinReservationChannel();
// 	});

// 	socket.on("connect", function () {
// 		dispatch.changeSocketState("connected");
// 		dispatch.createReservation(socket);
// 	});
// 	// we dispatch to avoid a race condition from a stale state
// };

// handles the response from reservation.confirmed
// adds missing fields to the response
export const decorateReservation = (state, dispatch, confirmed) => {
	const reservation = Object.values(state.reservationsv2.byId).reduce(
		(def, r) => (r._id === confirmed._id ? def.concat(r) : def),
		[]
	);

	reservation.forEach((r) => {
		dispatch.reservationConfirmed(confirmed);
		dispatch.clearReservationTimeouts(r);
	});
};

export const createReservation = (state, dispatch, socket) => {
	state.reservationsv2.queued.ids.forEach((id) => {
		const payload = {
			...state.reservationsv2.byId[id],
			token: state.user.token,
		};
		socket.emit("reservation.create", payload);
	});
};

export const fetchUserReservations = async (state, dispatch) => {
	if (state.user.guest) return;
	//if (state.myReservations.byId) return;
	dispatch.fetchUserReservationsReducer();
	await dispatch.reservationService({
		method: "get",
		path: `/user`, // /${encodeURIComponent(state.user.email)}`,
		onSuccess: dispatch.reservationsFetched,
		onFailure: dispatch.reservationsFetchFailed,
	});
};

export const fetchUserReservationsV1 = async (state, dispatch) => {
	if (state.user.guest) return;
	dispatch.fetchUserReservationsReducer();
	await dispatch.apiCall({
		method: "get",
		path: `/user/reserves`,
		onSuccess: dispatch.reservationsV1Fetched,
		onFailure: dispatch.reservationsFetchFailed,
	});
};

export const fetchForms = async (state, dispatch, action) => {
	const { officeId, lineId, isTicket } = action;
	dispatch.reservationsFetchedIsGoingOn();
	await dispatch.formsApi({
		method: "get",
		officeId,
		lineId,
		isTicket,
		onSuccess: isTicket
			? dispatch.ticketsFormsFetchedReducer
			: dispatch.reservationFormsFetchedReducer,
		onFailure: isTicket
			? dispatch.ticketFormsFetchedReducerFailure
			: dispatch.reservationFormsFetchedReducerFailure,
	});
};

export const reservationsV1Fetched = (state, dispatch, action) => {
	dispatch.addMyReservationsV1ToState(
		action
			.map(addCode)
			.filter((r) => new Date(r.from).getTime() > new Date().getTime())
	);
};

const halfAnHourInThePast = new Date().getTime() - 1000 * 60 * 30;

export const reservationsFetched = (state, dispatch, action) => {
	const reservations = action
		.filter((v2) => !v2.deleted_at && !v2.attended)
		.filter((v2) => new Date(v2.to).getTime() > halfAnHourInThePast)
		.map(mapReservationsToV2);
	dispatch.reservationsFetchedReducer(reservations);
	dispatch.addMyReservationsV1ToState(reservations);
};

export const deleteReservation = (state, dispatch, action) => {
	dispatch.fetchUserReservationsReducer();
	const { reservationId, lineSlug } = action;
	dispatch.reservationService({
		method: "delete",
		path: `/${reservationId}`,
		onSuccess: () =>
			dispatch.reservationDeleted({ reservationId, lineSlug }),
		onFailure: dispatch.reservationDeletionFailed,
	});
};

export const deleteReservationV3 = (state, dispatch, action) => {
	dispatch.fetchUserReservationsReducer();
	const { reservationId, lineSlug } = action;
	dispatch.reservationServiceV3({
		method: "delete",
		path: `/${reservationId}`,
		onSuccess: () =>
			dispatch.reservationDeleted({ reservationId, lineSlug }),
		onFailure: dispatch.reservationDeletionFailed,
	});
};

// rename to requestTimeBlocks
export const getTimeBlocks = (state, dispatch, action) => {
	const { date, officeSlug, lineSlug } = action;
	dispatch.timeBlocksReset();
	// this is wrong: what happens on concurrent requests? uh? UH?
	if (state.timeblocks.isLoading) return;
	const tzDate = getDateWithUserAgentTimezone(date).join("-");
	//if (state.timeblocks.byId[`${lineSlug}-${tzDate}`]) return;
	dispatch.timeBlocksRequested(action);
	dispatch.reservationService({
		method: "get",
		path: `/offices/${officeSlug}/${lineSlug}/${tzDate}/timeblocks`,
		onSuccess: (tb) => dispatch.timeBlocksGotten({ ...action, tb, tzDate }),
		onFailure: dispatch.timeBlocksRetrievalFailure,
	});
};

export const getTimeBlocksV3 = (state, dispatch, action) => {
	const { date } = action;
	dispatch.timeBlocksReset();
	if (state.timeblocks.isLoading) return;
	const tzDate = getDateWithUserAgentTimezone(date).join("-");

	const timeBlocks = state.reservationsv3.dataTimeblocks;
	const dateDayFormated = DateTime.fromISO(tzDate, { zone: "utc" }).toISO();
	const { blocks = [] } =
		timeBlocks.find((item) => item.date === dateDayFormated) || {};

	dispatch.timeBlocksGotten({
		...action,
		tb: blocks.map((item) => ({
			from: item.from,
			to: item.to,
			availables: item.slots,
		})),
		tzDate,
	});
};

export const handleSetDataTimeBlocksV3 = (state, dispatch, action) => {
	const { data } = action;

	dispatch.setDataTimeBlocksV3(data);
};

export const handleSetOldIdReservation = (state, dispatch, action) => {
	dispatch.setOldIdReservation(action);
};

const addZeroPrefix = (s) => ("0" + String(s)).substr(-2);

// returns datetime according to the user agent's configured time zone.
const getDateWithUserAgentTimezone = (date) => [
	date.getFullYear(),
	addZeroPrefix(date.getMonth() + 1),
	addZeroPrefix(date.getDate()),
];
