// @flow

import * as L from "partial.lenses";
import * as R from "ramda";
import { _produce } from "./util.js";
import moment from "moment";
import type { Call, InputTicket, Line, Office, Reserve, Ticket } from "types";
import type { SyncReducer } from "./types";

export const initTickets = _produce((state, tickets) => {
	tickets.forEach((t) => {
		if (state.tickets.some((t0) => t0.line_id === t.line_id)) return;
		state.tickets.push(t);
	});
});

type NewTicketArgs = { ticket: InputTicket, office: string };
export const newTicket: SyncReducer<NewTicketArgs> = (
	state,
	_,
	{ ticket, office }
) => {
	const new_ticket = R.pipe(R.mergeRight({ office }))(ticket);
	return L.modify("tickets", R.append(new_ticket), state);
};

export const updateTicket: SyncReducer<Ticket> = (state, _dsptch, ticket) => {
	// TODO: use Lenses
	const lens = R.lensProp("tickets");
	const idx = R.findIndex(R.propEq("id", ticket.id), state.tickets);
	const updateFn = R.update(idx, ticket);
	return R.over(lens, updateFn, state);
};

export const updateReserve: SyncReducer<Reserve> = (state, _, reserve) => {
	const lens = R.lensProp("reserves");
	const idx = R.findIndex(R.propEq("id", reserve.id), state.reserves);
	const updateFn = R.update(idx, reserve);
	return R.over(lens, updateFn, state);
};

type CallArgs = {
	call: Call,
	office: Office,
	line: Line,
};

export const callTicket: SyncReducer<CallArgs> = (state, _dsptch, args) => {
	const { call, office, line } = args;
	const ticket: Ticket = call.ticket;
	const ticket_lens = R.lensProp("tickets");
	const notif_lens = R.lensProp("notifications");
	const call_lens = R.lensProp("calls");

	return R.compose(
		R.over(ticket_lens, R.reject(R.propEq("tuid", ticket.tuid))),
		R.over(
			notif_lens,
			R.append({ type: "call", ticket, office, line, call })
		),
		R.over(call_lens, R.append(call))
	)(state);
};

export const almostThere: SyncReducer<CallArgs> = (state, _dpstch, args) => {
	const { call, line, office } = args;
	const ticket = call.ticket;
	return L.modify(
		"notifications",
		R.append({ type: "almost_there", ticket, office, line }),
		state
	);
};

export const skipped: SyncReducer<void> = (state) => {
	return L.modify("notifications", R.append({ type: "skipped" }), state);
};

export const alert: SyncReducer<{ message: string }> = (state, _, a) => {
	if (a instanceof Error) console.error(a);
	return R.over(
		R.lensProp("notifications"),
		R.append({ type: "alert", message: a.message }),
		state
	);
};

export const addNotification = _produce((state, action) => {
	//  []
	//  	.concat(action)
	//  	.forEach(notification => state.notifications.push(notification));
});

export const closeNotification = _produce((state, _) => {
	state.notifications.shift();
});

const evolve = R.evolve<InputTicket>({
	inserted_at: moment,
});
