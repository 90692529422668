export const reduce = (cb, acc) => (arr) => arr.reduce(cb, acc);
export const map = (cb) => (arr) => arr.map(cb);
export const filter = (cb) => (arr) => arr.filter(cb);
export const sort = (cb) => (arr) => arr.sort(cb);

export const pipe =
	(...functions) =>
	(args) =>
		functions.reduce((arg, fn) => fn(arg), args);

export const asyncmap = (cb) => (arr) => Promise.all(arr.map(cb));

export const asyncpipe =
	(...functions) =>
	(input) =>
		functions.reduce(
			(chain, func) => chain.then(func),
			Promise.resolve(input)
		);

export const dedup = (v, i, arr) => arr.indexOf(v) === i;

export const tap = (msg) => (v) => {
	return v;
};

export { pathOr, curry, flip } from "ramda";
