import { _produce } from "../util.js";
import { normalize } from "normalizr";
import { TICKET_MAX_AGE } from "./middlewares";
import { ticketSchema } from "./schema";
import { dedup } from "lib/functions";

export const userTicketsGottenReducer = _produce((state, action) => {
	const { tickets, totalCount } = action;
	state.tickets = state.tickets.concat(tickets);
	if (totalCount) state.ticketsv2.historic.total = totalCount;
	const { ticketsv2 } = state;
	if (!ticketsv2.historic.byPage[ticketsv2.currentPage])
		ticketsv2.historic.byPage[ticketsv2.currentPage] = [];
	const { entities, result } = normalize(tickets, [ticketSchema]);
	result
		.filter(dedup)
		.map((id) => entities.ticket[id])
		.forEach((t) => {
			ticketsv2.all.push(t.tuid);
			if (
				new Date() - new Date(t.inserted_at) > TICKET_MAX_AGE ||
				t.called
			) {
				ticketsv2.historic.ids.push(t.tuid);
				ticketsv2.historic.byPage[ticketsv2.currentPage].push(t.tuid);
				return;
			}
			if (ticketsv2.active.some((id) => id === t.tuid)) return;
			ticketsv2.active.push(t.tuid);
		});
	Object.assign(ticketsv2.byId, entities.ticket);
	ticketsv2.historic.loading = false;
	ticketsv2.loading = false;
});

export const fetchUserTicketsReducer = _produce((state, action) => {
	if (action && action.filter === "historic") {
		state.ticketsv2.historic.loading = true;
		return;
	}
	state.ticketsv2.loading = true;
});

export const userTicketsRequestFailed = _produce((state, action) => {
	state.ticketsv2.loading = false;
	state.ticketsv2.error = action;
});

export const setTicketsCurrentPage = _produce((state, action) => {
	state.ticketsv2.currentPage = action;
});

export const nextUserTicketsPage = _produce((state, action) => {
	state.ticketsv2.currentPage++;
});
