import { TICKET_MAX_AGE } from "./middlewares";
import { dedup } from "lib/functions";
import { mapObjIndexed, filter as filterRamda } from "ramda";

export const getLineTickets = (state, lineSlug) => {
	return state.tickets.filter((t) => t.line_slug === lineSlug);
};

export const getUserTicket = (tickets, lineSlug) => {
	return tickets.active
		.map((id) => tickets.byId[id])
		.filter(
			(t) =>
				t.lineSlug == lineSlug || // for tickets coming from ticket:created
				t.line_slug === lineSlug ||
				t.line === lineSlug // for tickets coming from userio
		)
		.reduce((p, t) => t.id, null);
};

export const selectTicket = (tickets, lineSlug) => {
	return tickets
		.filter(
			(t) =>
				t.lineSlug == lineSlug ||
				t.line_slug === lineSlug ||
				t.line === lineSlug
		)
		.reduce((p, t) => t, null);
};

export const selectUserTicketById = (tickets, tuid) => tickets.byId[tuid];

export const selectUserTicketByLine = (tickets, lineSlug) => {
	return tickets.active
		.map((id) => tickets.byId[id])
		.filter(
			(t) =>
				t.lineSlug == lineSlug ||
				t.line_slug === lineSlug ||
				t.line === lineSlug
		)
		.reduce((p, t) => t, null);
};
export const selectUserReservationByLine = (reservations, lineSlug) => {
	const findReservation = (res, id) => {
		return reservations[id];
	};

	const result = filterRamda(
		(t) =>
			t.lineSlug == lineSlug ||
			t.line_slug === lineSlug ||
			t.line === lineSlug,
		mapObjIndexed(findReservation, reservations)
	);

	const keys = Object.keys(result);

	const reservation =
		keys.length &&
		keys
			.map((k) => (result[k].deleted ? null : result[k]))
			.reduce((p, t) => t);

	return reservation || null;
};
export const selectUserReservationByOffice = (reservations, officeSlug) => {
	const findReservation = (res, id) => {
		return reservations[id];
	};

	const result = filterRamda((t) => {
		return t.office === officeSlug;
	}, mapObjIndexed(findReservation, reservations));

	const keys = Object.keys(result);

	const reservation =
		keys.length &&
		keys
			.map((k) => (result[k].deleted ? null : result[k]))
			.reduce((p, t) => t);

	return reservation || null;
};

export const selectHistoricTicketsByPage = (tickets) => {
	if (!tickets.historic.byPage[tickets.currentPage]) return [];
	return tickets.historic.byPage[tickets.currentPage]
		.filter((id) => id)
		.map((id) => tickets.byId[id])
		.sort(
			(t0, t1) =>
				new Date(t1.inserted_at).getTime() -
				new Date(t0.inserted_at).getTime()
		);
};

export const selectActiveTickets = (tickets) =>
	tickets.active
		.map((id) => tickets.byId[id])
		// only return tickets generated in the last 6 hours
		.filter((t) => new Date() - new Date(t.inserted_at) < TICKET_MAX_AGE);

export const selectHistoricTickets = (tickets) =>
	tickets.historic.ids
		.filter(dedup)
		.filter((id) => id)
		.map((id) => tickets.byId[id])
		.sort(
			(t0, t1) =>
				new Date(t1.inserted_at).getTime() -
				new Date(t0.inserted_at).getTime()
		);
