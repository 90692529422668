import React, { useDispatch, useEffect, useGlobal, useState } from "reactn";
import { useScroll } from "../scroll";
import {
	selectActiveTickets,
	selectHistoricTickets,
	selectHistoricTicketsByPage,
} from "./selectors";
import { TICKETS_PER_PAGE, TICKETS_PER_SCREEN } from "./middlewares";
import { useAuth } from "hooks/useAuth";
import { ticketsFromLocalStorage } from "io/localStorage";

export const useActiveTickets = () => {
	const { isLoggedInTemporary, isLoggedIn } = useAuth();
	const [tickets] = useGlobal("ticketsv2");
	const fetchUserTickets = useDispatch("fetchUserTickets");

	useEffect(() => {
		if (!isLoggedInTemporary && isLoggedIn) {
			fetchUserTickets();
		}
	}, [fetchUserTickets]);

	const activeTickets = selectActiveTickets(tickets);

	return [
		// prevent to activate loading indicator
		// when loading more tickets
		!isLoggedInTemporary &&
			tickets.active < TICKETS_PER_PAGE &&
			tickets.loading,

		tickets.active < TICKETS_PER_PAGE && tickets.error,
		activeTickets,
	];
};

export const useHistoricTickets = () => {
	const [tickets] = useGlobal("ticketsv2");
	const { historic, currentPage, error } = tickets;
	const fetchMoreUserTickets = useDispatch("fetchMoreUserTickets");
	const setTicketsCurrentPage = useDispatch("setTicketsCurrentPage");
	const paginated = historic.ids.length >= TICKETS_PER_PAGE * currentPage;
	const historicTickets = selectHistoricTickets(tickets);

	const [screens] = useScroll();

	if ((TICKETS_PER_SCREEN * screens) / TICKETS_PER_PAGE > currentPage)
		setTicketsCurrentPage(currentPage + 1);

	useEffect(() => {
		fetchMoreUserTickets();
	}, [currentPage]);

	return [
		paginated && historic.loading,
		paginated && error,
		historicTickets,
		paginated,
	];
};

export const useTicketsPagination = () => {
	const [ticketsState] = useGlobal("ticketsv2");
	const { historic, currentPage, error } = ticketsState;
	const setTicketsCurrentPage = useDispatch("setTicketsCurrentPage");
	const fetchMoreUserTickets = useDispatch("fetchMoreUserTickets");

	const tickets = selectHistoricTicketsByPage(ticketsState);

	useEffect(() => {
		fetchMoreUserTickets();
	}, [currentPage]);

	useEffect(() => () => setTicketsCurrentPage(1), []);

	return [
		tickets,
		currentPage,
		setTicketsCurrentPage,
		historic.total,
		historic.loading,
		error,
	];
};
