// @flow

import * as L from "partial.lenses";
import * as R from "ramda";
import { calculate_distance } from "lib/geolocation";
import { enableES5, produce } from "immer";
import { mergeDeep } from "lib";
import { normalize, schema } from "normalizr";
import moment from "moment";
import type {
	Category,
	Channel,
	Distance,
	HourBlock,
	Office,
	Offices,
} from "types";
import type { SyncReducer } from "./types";

enableES5();

const updateOffice = (office) => {
	if (office.lines) {
		const lines = R.indexBy(R.prop("slug"), office.lines);
		return R.assoc("lines", lines, office);
	} else {
		return office;
	}
};

export const setOffices: SyncReducer<Offices> = (state, _, offices) => {
	return mergeDeep(state, { offices: R.map(updateOffice, offices) });
};

type AddChannelArgs = {
	channel: Channel,
	office: string,
};

export const addChannel: SyncReducer<AddChannelArgs> = (
	state,
	_,
	{ channel, office }
) => {
	return R.assocPath(["offices", office, "channel"], channel, state);
};

export const removeChannel: SyncReducer<string> = (state, _, office) => {
	//return R.dissocPath(['offices', office, 'channel'], state);
	return L.remove(["offices", office, "channel"], state);
};

export const setCategories: SyncReducer<Category[]> = (
	state,
	_,
	categories
) => {
	const cats = R.indexBy<Category, $ReadOnly<any>>(
		R.compose(String, R.prop("id")),
		categories
	);
	return R.over(
		R.lensProp("offices"),
		R.map((office) =>
			R.assoc("category", cats[office.category_id], office)
		),
		state
	);
};

type OfficeBlocks = {
	office: string,
	blocks: HourBlock[],
};

export const setOfficeBlocks: SyncReducer<OfficeBlocks> = (
	state,
	_,
	{ office, blocks }
) => {
	const lens = R.lensPath(["offices", office.slug, "hours"]);
	return R.set(lens, R.map(evolveBlock, blocks), state);
};

type OfficeDistance = {
	distance: Distance,
	office: string,
};

export const setOfficesDistance: SyncReducer<Array<OfficeDistance>> = (
	state,
	_,
	distances
) => {
	return R.reduce(
		(state, { distance, office }) => {
			return R.assocPath(
				["offices", office, "distance"],
				distance,
				state
			);
		},
		state,
		distances
	);
};

export const mergeOfficeState: SyncReducer<Office> = (state, _, office) => {
	return R.over(
		R.lensPath(["offices", office.slug]),
		R.merge(updateOffice(office)),
		state
	);
};

const evolveBlock = R.evolve({
	from: (from) => from, //moment(from, "HH:mm:ss.zzz"),
	to: (to) => to, //moment(to, "HH:mm:ss.zzz")
});

export const getCategoriesX: Reducer<void> = async (state, dispatch) => {
	if (Object.keys(state.categories.byId).length) return;
	dispatch.getCategoriesReducer();
	dispatch.apiCall({
		path: "/categories?mode=visible",
		method: "get",
		onSuccess: dispatch.getCategoriesSuccess,
		onFailure: dispatch.getCategoriesFailure,
	});
};

// This is an adapter/decorator. It enables ReactN reducers
// to comply with immer's `produce` function signature.
const p = (fn) => (s, _, a) => produce(fn)(s, a);

export const getCategoriesReducer = p((state, _) => {
	state.categories.isLoading = true;
	state.categories.errorMessage = null;
});

const categoriesSchema = [new schema.Entity("categories")];
export const getCategoriesSuccess = p((state, action) => {
	state.categories.byId = normalize(
		action,
		categoriesSchema
	).entities.categories;
	state.categories.isLoading = false;
});

// This is a more verbose alternative to getCategoriesSuccess
// in which `produce` is used directly, without the adapter.
export const getCategoriesFailure = (state, _, action) =>
	produce(state, (state) => {
		state.categories.isLoading = false;
		state.categories.errorMessage = action;
	});

export const calculateOfficeDistances: Reducer<Office[]> = async (
	state,
	dispatch,
	position
) => {
	// const mockCoords = {
	// 	coords: {
	// 		latitude: -33.4502004,
	// 		longitude: -70.6542286,
	// 	},
	// };
	const distances = Object.keys(state.offices).map((slug) => ({
		office: slug,
		distance: calculate_distance(position, state.offices[slug]),
	}));
	dispatch.setOfficesDistance(distances);
};
