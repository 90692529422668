import { _produce } from "../util.js";

export const addCategoryFilter = _produce((state, { category }) => {
	state.search.category = category;
});

export const removeCategoryFilter = _produce((state) => {
	state.search.category = {};
});

export const addDistrictFilter = _produce((state, { district }) => {
	const filters = { district };
	state.search = { ...state.search, filters };
});

export const setDistricts = _produce((state, districts) => {
	state.search.districts = districts;
});

export const removeDistrictFilter = _produce((state) => {
	state.search.filters.district = "";
});

export const setSearchValue = _produce((state, searchValue) => {
	state.search.searchValue = String(searchValue);
});

export const clearSearchValue = _produce((state) => {
	state.search.searchValue = "";
});

export const setResultsQuantity = _produce((state, { quantity }) => {
	state.search.resultsQuantity = quantity;
});
