import { pathOr } from "ramda";
import { toManyRequestsTextDefinitions } from "lib/helpers/toManyRequestsTextDefinitions";
import { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import { useTimer } from "hooks/useTimer";

export const useHandleToManyRequestBehaivor = () => {
	const [toManyRequestsParams] = useGlobal("toManyRequestsParams");
	const [showOfficeMOdal, setShowOfficeModal] = useState(false);
	const textToModal = toManyRequestsTextDefinitions.toManyRequestsText.es;

	const toManyRequestError = pathOr(
		false,
		["toManyRequestError"],
		toManyRequestsParams
	);
	// const numberOfTry = pathOr(false, ["numberOfTry"], toManyRequestsParams);
	// const getTimerValue = (numberOfTry) => {
	// 	if (numberOfTry === 0) {
	// 		return 5;
	// 	} else if (numberOfTry === 1) {
	// 		return 30;
	// 	} else {
	// 		return 60;
	// 	}
	// };

	// const timerValue = getTimerValue(numberOfTry);
	// const { time } = useTimer(timerValue);
	const onClickForToManyRequestHandler = () => {
		return window.location.reload();
	};
	useEffect(() => {
		if (toManyRequestError) {
			setShowOfficeModal(true);
		}
	}, [toManyRequestError]);

	return {
		showOfficeMOdal,
		textToModal,
		onClickForToManyRequestHandler,
	};
};
