// @flow
import * as R from "ramda";
import {
	fetchLine,
	fetchLines,
	fetchLinesState,
	findLine,
} from "network/requests";
import type { Line, Office, Reserve, Ticket } from "types";
import type { Reducer } from "../types";

const mergeLines = (lines1: Line[], lines2: Line[]) => {
	return R.pipe(
		R.groupBy(R.prop("slug")),
		R.map(R.apply(R.merge))
	)([...lines1, ...lines2]);
};

/**
 * Fetches lines from a given Office from the server and adds them to the global
 * state.
 * If failed to get the office it will do nothing.
 * @async
 * @param {string} office_slug the slug of the Office to fetch lines from.
 */
export const getLines: Reducer<string> = async (
	state,
	dispatch,
	office_slug
) => {
	// $FlowFixMe
	const office: ?Office = R.pathOr(
		undefined,
		["offices", office_slug],
		state
	);
	// FIXME: esta comprobación falla cuando hay solo una fila
	// cargada en el estado porque asume que están cargadas
	// todas las filas de la oficina.
	// if (state.lines.some(l => l.office_id == office.id)) return;
	if (office) {
		const lines: Line[] = await fetchLines(office.id);
		const lines_states = await fetchLinesState(office_slug);
		const mergedLines = mergeLines(lines, lines_states);
		dispatch.addLines(Object.values(mergedLines));
		if (R.isNil(office.lines)) {
			await dispatch.initLines({
				office_slug,
				lines: mergedLines,
			});
		}
	}
};

type Slugs = { office_slug: ?string, line_slug: ?string };

/**
 * Fetches one line from the server and adds it to the global state.
 * @async
 * @param {Object} slugs an object with keys `{ office_slug, line_slug }`
 * @throws if cannot find the line.
 */
export const getLine: Reducer<Slugs> = async (
	_,
	dispatch,
	{ office_slug, line_slug }
) => {
	if (R.isNil(office_slug)) throw TypeError("office_slug is undefined");
	if (R.isNil(line_slug)) throw TypeError("line_slug is undefined");
	const line: ?Line = await fetchLine(office_slug, line_slug);
	if (!line)
		throw TypeError(
			`line is undefined:
      office: ${office_slug || "undefined"},
      line: ${line_slug || "undefined"}`
		);
	await dispatch.addLine({ line, office_slug });
};

/**
 * Queries the server for a line given an ticket.
 * @async
 * @params {Ticket} ticket
 */
export const findLineByTicket: Reducer<Ticket> = async (
	state,
	dispatch,
	ticket
) => {
	if (ticket.line && ticket.office) return;
	const { office_slug, line_slug } = await findLine(ticket.line_id);
	await dispatch.updateTicket(
		R.merge(
			{
				office: office_slug,
				line: line_slug,
			},
			ticket
		)
	);
	if (R.isNil(R.path(["offices", office_slug, "lines", line_slug], state))) {
		await dispatch.getLine({ office_slug, line_slug });
	}
};

export const findLineByReserve: Reducer<Reserve> = async (
	state,
	dispatch,
	reserve
) => {
	if (reserve.office && reserve.line) return;
	const { office_slug, line_slug } = await findLine(reserve.line_id);
	await dispatch.updateReserve(
		R.merge(
			{
				office: office_slug,
				line: line_slug,
			},
			reserve
		)
	);
	if (R.isNil(R.path(["offices", office_slug, "lines", line_slug], state))) {
		await dispatch.getLine({ office_slug, line_slug });
	}
};
