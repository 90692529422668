import React, { useEffect, useState, useGlobal, useDispatch } from "reactn";
import { useActiveTickets, useHistoricTickets } from "state/tickets/hooks";
import {
	setTicketLocalStorage,
	ticketsFromLocalStorage,
	reservationsFromLocalStorage,
} from "io/localStorage";
import { useAuth } from "./useAuth";

export const useSetLocalStorageTickets = (ticket) => {
	// useEffect(() => {
	// 	if (ticket && ticket.tuid) {
	// 		setTicketLocalStorage(ticket);
	// 	}
	// }, []);
	return;
};

export const useLocalStorageTickets = () => {
	const [localTickets, setLocalTickets] = useState([]);
	useEffect(() => {
		const { userTickets } = ticketsFromLocalStorage();

		setLocalTickets(userTickets);
	}, []);

	return { localTickets };
};

export const useTemporaryTicketsV2 = () => {
	const { isLoggedInTemporary } = useAuth();
	const setTemporaryReservations = useDispatch("setTemporaryReservations");
	const ticketCreated = useDispatch("ticketCreated");
	const { userTickets } = ticketsFromLocalStorage() ?? {};
	const { userReservations } = reservationsFromLocalStorage() ?? {};

	const setTickets = () => {
		if (isLoggedInTemporary)
			userTickets &&
				userTickets.length &&
				userTickets.map((t) =>
					ticketCreated({ ...t, temporaryUser: true })
				);
	};
	const setReservations = () => {
		if (isLoggedInTemporary)
			userReservations &&
				userReservations.length &&
				userReservations.map((r) =>
					setTemporaryReservations({ ...r, temporaryUser: true })
				);
	};

	return { setTickets, setReservations };
};
