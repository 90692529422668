// Joins a channel. If ticket information is provided, it goes
// on with the next step of the saga.
export const joinChannel = async (state, dispatch, action) => {
	const { officeSlug, isReserve, isTicket } = action;
	dispatch.acquireChannelMutex({ officeSlug });
	const { user, socket } = state;
	if (user.guest) return;
	// this prevents a possible race condition if line is not loaded
	// when ticket is picked
	//if (lineId) await dispatch.getLineX({ lineId });
	if (state.channels.byId[officeSlug] && isTicket) {
		dispatch.pickTicket(action);
		return;
	}
	// TODO: use a queue in the state to react to pending
	// reservations instead of imperatively calling them
	// here.
	if (state.channels.byId[officeSlug] && isReserve) {
		dispatch.pickReserve(action);
		return;
	}
	if (
		state.channels[officeSlug] &&
		state.channels[officeSlug].lock === true
	) {
		console.warn("tried to join a locked channel");
		return;
	}
	const channel = socket.channel(`web:${officeSlug}`, { token: user.token });
	channel
		.join(12000)
		.receive("ok", (_) => dispatch.channelJoined({ ...action, channel }))
		.receive("error", (err) => dispatch.channelJoinException(err))
		.receive("timeout", (err) => dispatch.channelJoinTimeoutException(err));
};

// Subscribes to the creation of calls and dispatches the action
// of picking a ticket.
export const channelJoined = async (state, dispatch, action) => {
	// to ensure compatibility with other impl
	const { channel, officeSlug, isReserve, isTicket } = action;
	dispatch.addChannel({ channel, office: officeSlug });
	dispatch.addChannelX({ channel, officeSlug });
	dispatch.channelJoinedReducer({ officeSlug, channel });
	channel.on("call:created", (call) =>
		dispatch.callCreated({ ...call, officeSlug })
	);
	channel.on("reserve:updated", (r) => {
		if (r.confirmed) dispatch.reserveConfirmed(r);
	});

	channel.on("ticket:created", (ticket) => {
		dispatch.updateLine({ ...ticket, field: "waiting" });
	});

	channel.on("ticket:error", dispatch.ticketErrored);

	channel.on("time:estimate", (w) =>
		dispatch.updateLine({ ...w.data, field: "wait" })
	);
	if (isTicket) dispatch.pickTicket(action);
	if (isReserve) dispatch.pickReserve(action);
};

export const leaveChannel = async (state, dispatch, action) => {
	const { officeSlug } = action;
	const channel = state.channels.byId[officeSlug];
	if (!channel) {
		console.warn("tried to leave an unexisting channel");
		return;
	}
	if (
		state.tickets
			.concat(state.reserves)
			.some((t) => t.office === officeSlug || t.officeSlug === officeSlug)
	) {
		console.warn("tried to leave a channel with tickets or reserves");
		return;
	}
	if (
		Object.values(state.myReservations.byId).some(
			(r) => r.office === officeSlug || r.officeSlug === officeSlug
		)
	) {
		console.warn("");
		return;
	}
	channel
		.leave()
		.receive("ok", () => {
			dispatch.channelLeft({ officeSlug });
		})
		.receive("error", (err) => {
			console.error(err);
		});
	return;
};

// Implementación futura. Actualmente no está en uso.
export const joinChannelX = async (state, dispatch, action) => {
	dispatch.acquireChannelMutex({ officeSlug });
	if (
		state.channels[officeSlug] &&
		state.channels[officeSlug].lock === true
	) {
		console.warn("tried to join a locked channel");
		return;
	}
	const { socket, user } = state;
	const { officeSlug } = action;
	const channel = socket.channel(`web:${officeSlug}`, { token: user.token });
	channel
		.join(12000)
		.receive("ok", (_) => {
			dispatch.subscribeToChannelEvents({ ...action, channel });
			dispatch.channelJoined({ ...action, channel });
			dispatch.pickPendingTickets({ ...action, channel });
			//dispatch.pickPendingReservations({lineId});
		})
		.receive("error", (err) => dispatch.channelJoinException(err))
		.receive("timeout", (err) => dispatch.channelJoinTimeoutException(err));
};

export const subscribeToChannelEvents = async (state, dispatch, action) => {
	const { channel, officeSlug } = action;
	channel.on("call:created", (call) =>
		dispatch.callCreated({ ...call, officeSlug })
	);
	channel.on("reserve:updated", (r) => {
		if (r.confirmed) dispatch.reserveConfirmed(r);
	});
	channel.on("time:estimate", (w) =>
		dispatch.updateLine({ ...w.data, field: "wait" })
	);
};
