import React, { useState } from "reactn";

export const useScroll = () => {
	const [scrolledScreens, setScroll] = useState(1);

	window.onscroll = () => {
		const screenN = Math.ceil(
			(window.innerHeight + window.scrollY) / window.innerHeight
		);
		if (screenN + 100 > scrolledScreens) setScroll((_) => screenN);
	};

	return [scrolledScreens];
};
