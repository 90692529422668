import styled from "styled-components";

export const StyledContainer = styled.div`
	background: transparent;
	position: fixed;
	top: 0;
	z-index: 1000;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
`;

export const StyledModal = styled.div`
	display: flex;
	width: 60%;
	height: 25vh;
	text-align: center;
	background: #fefefe;
	font-family: "Montserrat";
	margin: 0 auto;
	border-radius: 20px;
	box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.4);

	@media (min-width: 768px) {
		max-width: 460px;
	}
`;

export const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
	justify-content: space-evenly;
	width: 60%;
	height: 60%;
	margin: 0 auto;
`;
