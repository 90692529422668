import { GrowthBook } from "@growthbook/growthbook-react";
import { getFeatureFlags } from "network/requests";
import React, { useCallback } from "react";

export const useFeatureFlags = () => {
	const [flagsLoad, setFlagsLoad] = React.useState(false);
	const [gw] = React.useState(
		new GrowthBook({
			// Callback when a user is put into an A/B experiment
			trackingCallback: (experiment, result) => {
				console.log("Experiment Viewed", {
					experimentId: experiment.key,
					variationId: result.variationId,
				});
			},
		})
	);

	const getFeatureFlagsBehaivor = useCallback(() => {
		getFeatureFlags()
			.then((features) => {
				gw.setFeatures(features);
				setFlagsLoad(true);
			})
			.catch((err) => {
				setFlagsLoad(true);
			});
	}, [gw]);

	React.useEffect(() => {
		getFeatureFlagsBehaivor();
		const interval = setInterval(() => {
			getFeatureFlags().then((features) => {
				gw.setFeatures(features);
			});
		}, 60000);

		return () => clearInterval(interval);
	}, []);

	return { flagsLoad, features: gw };
};
