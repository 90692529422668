import { enableES5, produce } from "immer";

enableES5();

export const _produce = (fn) => (s, _, a) => {
	if (process.env.NODE_ENV === "development") {
		// console.log("this action was called", a);
	}
	return produce(fn)(s, a);
};

// Finds the index of the array element to remove using indexOf,
// and then removes that index with splice.
export const removeOne = (array, element) => {
	const index = array.indexOf(element);
	if (index > -1) {
		array.splice(index, 1);
	}
};
