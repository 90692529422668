// @flow

import * as R from "ramda";
import { _produce } from "./util";

const DEFAULT = {
	selected: "es",
};

export const languageReducer = (state) => {
	return R.assoc("language", DEFAULT, state);
};

export const setLanguageInitial = _produce((state, user) => {
	state.language.selected = "es";
});

export const setLanguage = _produce((state, payload) => {
	state.language.selected = payload;
});
